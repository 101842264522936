import React, { useState, useEffect, useMemo } from 'react';
import {
    Container,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Box
} from '@mui/material';
import { host } from './tableConfig';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "./Util";
import useUserStore from './store/userStore';

interface InventoryItem {
    id: number;
    name: string;
    count: string;
    metric_name: string;
}

interface InventoryManagementProps {
    inventoryType: 'ingredient' | 'product';
}

type Order = 'asc' | 'desc';

const Inventory: React.FC<InventoryManagementProps> = ({ inventoryType }) => {
    const [selectedClientId, setSelectedClientId] = useState<string>('');
    const [inventoryData, setInventoryData] = useState<InventoryItem[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof InventoryItem>('name');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    // Get user and clients from the store
    const { user } = useUserStore();

    // Memoize the clients array
    const clients = useMemo(() => user?.clients || [], [user?.clients]);

    // Determine if we're dealing with a single client
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId.toString());
            fetchClientData(user.clientId.toString());
        }
    }, [isSingleClient, user?.clientId]);

    const handleClientChange = (event: SelectChangeEvent<string>) => {
        const clientId = event.target.value;
        setSelectedClientId(clientId);
        fetchClientData(clientId);
    };

    const fetchClientData = async (clientId: string) => {
        try {
            const response = await axiosWrapper({url:`${host}/inventory-${inventoryType}/${clientId}`});
            setInventoryData(response.data);
        } catch (error) {
            console.error('Error fetching client data:', error);
        }
    };

    const handleRequestSort = () => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    const sortedInventoryData = inventoryData.sort((a, b) => {
        return order === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
    });

    const title = inventoryType === 'ingredient' ? 'Ingredient Receiving' : 'Packaging Receiving';

    // Memoize the client menu items
    const clientMenuItems = useMemo(() =>
            clients.map((client) => (
                <MenuItem key={client.id} value={client.id.toString()}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    return (
        <Container maxWidth="md">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
                {!isSingleClient ? (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select<string>
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            onChange={handleClientChange}>
                            {clientMenuItems}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientId && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Client: {user?.clientName}
                        </Typography>
                    )
                )}
            </Box>

            {inventoryData.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={true}
                                        direction={order}
                                        onClick={handleRequestSort}>
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    Count
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedInventoryData.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.count} {item.metric_name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default Inventory;