export const chart = `
graph TD
    M[Ingredients<br>List] -.-> E
    N[Packaging<br>List] -.-> D
    O[Brands<br>List] -.-> D
    D[Products<br>List] -.-> E
    E[Scheduled<br>Processes] --> F
    F[Batches] --> G
    G[Shipments]
    H[Distributor<br>List] -.-> G
    G -.-> I[Recalls]

    class D,E,F,G,H,I,M,N,O clickable;

    classDef clickable cursor:pointer;
    classDef default fill:#4CAF50,stroke:#333,stroke-width:2px,color:white;
    classDef list fill:#2196F3,stroke:#333,stroke-width:2px,color:white;
    classDef purple fill:#9C27B0,stroke:#333,stroke-width:2px,color:white;

    class M,N,O,D,H list;
    class G purple;
      `;

