import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {Help, Close, HelpOutline} from '@mui/icons-material';
import Markdown from 'markdown-to-jsx';

interface HelpDialogProps {
    open: boolean;
    onClose: () => void;
    content: string;
    title?: string;
}

// Reusable Help Icon Button component

export const HelpIconButton: React.FC<{
    onClick: (e: React.MouseEvent) => void;
    sx?: any;
}> = ({ onClick, sx = {} }) => (
    <IconButton
        onClick={(e) => {
            e.stopPropagation();
            onClick(e);
        }}
        size="small"
        sx={{
            ml: 1,
            backgroundColor: 'white',
            '&:hover': {
                backgroundColor: 'white',
            },
            ...sx
        }}
    >
        <HelpOutline color="primary" fontSize="small" />
    </IconButton>
);

// Main Help Dialog component
const HelpDialog: React.FC<HelpDialogProps> = ({
                                                   open,
                                                   onClose,
                                                   content,
                                                   title = 'Help'
                                               }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Markdown to MUI component mapping
    const options = {
        overrides: {
            h1: {
                component: Typography,
                props: {
                    variant: 'h5',
                    color: 'primary',
                    gutterBottom: true,
                    sx: { fontWeight: 600 }
                },
            },
            h2: {
                component: Typography,
                props: {
                    variant: 'h6',
                    color: 'primary',
                    gutterBottom: true,
                    sx: { mt: 3 }
                },
            },
            p: {
                component: Typography,
                props: { paragraph: true },
            },
            ul: {
                props: {
                    sx: { mb: 2 }
                },
            },
            ol: {
                props: {
                    sx: { mb: 2 }
                },
            },
            blockquote: {
                props: {
                    sx: {
                        borderLeft: 4,
                        borderColor: 'info.main',
                        bgcolor: 'info.lighter',
                        p: 2,
                        my: 2
                    }
                },
            },
        },
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={isMobile}
            PaperProps={{
                sx: {
                    m: isMobile ? 0 : 2,
                    height: isMobile ? '100%' : 'auto',
                    maxHeight: isMobile ? '100%' : 'calc(100% - 32px)',
                    borderRadius: isMobile ? 0 : 1
                }
            }}
        >
            <DialogTitle sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                zIndex: 1
            }}>
                <Typography variant="h6" component="div" sx={{ color: 'primary.main', fontWeight: 600 }}>
                    {title}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                p: isMobile ? 2 : 3,
                '&:first-of-type': {
                    pt: isMobile ? 2 : 3
                }
            }}>
                <Markdown options={options}>
                    {content}
                </Markdown>
            </DialogContent>
        </Dialog>
    );
};

export default HelpDialog;