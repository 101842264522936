import React, { useState, useEffect, useMemo } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    CircularProgress,
    Box,
    Button,
    Snackbar,
    Alert,
    Typography
} from '@mui/material';
import BatchDialog from './BatchDialog';
import BatchTable from './BatchTable';
import { host } from '../tableConfig';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import useUserStore from '../store/userStore';
import {BatchData} from "../types";

interface Process {
    id: number;
    name: string;
    client: number;
}

const PanelThree: React.FC = () => {
    const [processes, setProcesses] = useState<Process[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedProcess, setSelectedProcess] = useState<number | ''>('');
    const [selectedBatch, setSelectedBatch] = useState<BatchData | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newBatchAdded, setNewBatchAdded] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const { getToken } = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    // Get user and clients from the store
    const { user } = useUserStore();

    // Memoize the clients array
    const clients = useMemo(() => user?.clients || [], [user?.clients]);

    // Determine if we're dealing with a single client
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            fetchProcesses(user.clientId);
        }
    }, [isSingleClient, user?.clientId]);

    const fetchProcesses = async (clientId: number) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosWrapper({url: `${host}/getSQLResults/list_scheduled_processes?clientId=${clientId}`});
            const data = response.data;
            if (data.result === "success" && Array.isArray(data.data)) {
                setProcesses(data.data);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (e) {
            console.error('Error fetching processes:', e);
            setError(`Failed to fetch processes: ${e instanceof Error ? e.message : String(e)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        setSelectedProcess('');
        fetchProcesses(clientId);
    };

    const handleAddClick = async () => {
        try {
            const response = await axiosWrapper({
                url:`${host}/insert-batch`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    process: selectedProcess,
                },
            });

            const result = response.data;

            if (result.success) {
                const newBatch: BatchData = {
                    ...result.data,
                    process_name: processes.find(p => p.id === selectedProcess)?.name || '',
                    client_name: clients.find(c => c.id === selectedClientId)?.name || '',
                };
                setSelectedBatch(newBatch);
                setDialogOpen(true);
                setSnackbarMessage('Batch created successfully');
                setSnackbarSeverity('success');
                setNewBatchAdded(prev => prev + 1);
            } else {
                throw new Error(result.message || 'Failed to create batch');
            }
        } catch (error) {
            console.error('Error creating batch:', error);
            setSnackbarMessage(`Failed to create batch: ${error instanceof Error ? error.message : String(error)}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedBatch(null);
    };

    const handleSaveDialog = async (updatedBatch: BatchData, isDone: boolean) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/update-batch`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    ...updatedBatch,
                    isDone,
                },
            });

            if (!response.data) {
                throw new Error('Failed to update batch');
            }

            setNewBatchAdded(prev => prev + 1);
            handleCloseDialog();
            setSnackbarMessage('Batch updated successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            console.error('Error updating batch:', error);
            setSnackbarMessage(`Failed to update batch: ${error instanceof Error ? error.message : String(error)}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleBatchUpdate = () => {
        setNewBatchAdded(prev => prev + 1);
    };

    // Memoize the client menu items
    const clientMenuItems = useMemo(() =>
            clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                pt={0}
            >
                <Box width="100%" maxWidth={'90%'} >
                    {!isSingleClient && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="client-select-label">Client</InputLabel>
                            <Select
                                labelId="client-select-label"
                                id="client-select"
                                value={selectedClientId}
                                label="Client"
                                onChange={handleClientChange}
                            >
                                {clientMenuItems}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl fullWidth margin="normal" disabled={!selectedClientId}>
                        <InputLabel id="process-select-label">Scheduled Process</InputLabel>
                        <Select
                            labelId="process-select-label"
                            id="process-select"
                            value={selectedProcess}
                            label="Process"
                            onChange={(e: SelectChangeEvent<number>) => setSelectedProcess(e.target.value as number)}
                        >
                            {processes.map((process) => (
                                <MenuItem key={process.id} value={process.id}>
                                    {process.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box mt={2} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={handleAddClick}
                            disabled={!selectedClientId || !selectedProcess}
                        >
                            Add
                        </Button>
                    </Box>
                </Box>

                {selectedClientId && (
                    <Box width="100%" maxWidth={800} mt={4}>
                        <BatchTable
                            newBatchAdded={newBatchAdded}
                            onBatchUpdate={handleBatchUpdate}
                            clientId={selectedClientId as number}
                        />
                    </Box>
                )}
            </Box>

            {selectedBatch && (
                <BatchDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onSave={handleSaveDialog}
                    batch={selectedBatch}
                    process={selectedProcess}
                />
            )}

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PanelThree;