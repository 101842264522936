import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Button,
    useMediaQuery,
    useTheme,
    Typography,
    Box
} from '@mui/material';
import { ScheduledProcess, Ingredient, IngredientOption, MetricOption } from './types';
import { host } from './tableConfig';
import { createAxiosWrapper, formatDate } from "./Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface ProductOption {
    id: number;
    name: string;
}

interface LotExpOption {
    lot: string;
    exp_date: string;
}

interface Product {
    id: number | null;
    lot: string | null;
    exp_date: string | null;
    price: number | null;
    count: number | null;
    lotExpOptions: LotExpOption[];
}

interface AddScheduledProcessDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (shipment: any) => Promise<boolean>;
    clientName: string;
}

const AddShipmentDialog: React.FC<AddScheduledProcessDialogProps> = ({ open, onClose, onSave, clientName }) => {
    const [distributorId, setDistributorId] = useState<number | ''>('');
    const [distributors, setDistributors] = useState<any[]>([]);
    const [notes, setNotes] = useState('');
    const [products, setProducts] = useState<Product[]>([]);
    const [productList, setProductList] = useState<ProductOption[]>([]);
    const [ticketNum, setTicketNum] = useState('');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchDistributors();
        fetchProductOptions();
    }, []);

    const fetchDistributors = async () => {
        try {
            const response = await axiosWrapper({url: `${host}/distributor`});
            const data = response.data;
            if (data.result === 'success') {
                setDistributors(data.data);
            }
        } catch (error) {
            console.error('Error fetching distributors:', error);
        }
    };

    const fetchProductOptions = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/product`});
            const data = response.data;
            if (data.result === 'success') {
                setProductList(data.data);
            }
        } catch (error) {
            console.error('Error fetching product options:', error);
        }
    };

    const fetchLotOptions = async (productId: number, index: number) => {
        try {
            const response = await axiosWrapper({url:`${host}/getSQLResults/list_lot_exp/${productId}`});
            const data = response.data;
            if (data.result === 'success') {
                setProducts(prevProducts => {
                    const updatedProducts = [...prevProducts];
                    updatedProducts[index].lotExpOptions = data.data;
                    return updatedProducts;
                });
            }
        } catch (error) {
            console.error(`Error fetching lot options for product ID ${productId}:`, error);
        }
    };

    const handleAddProduct = () => {
        setProducts(prevProducts => {
            const newProducts = [...prevProducts, { id: null, count: null, lot: null, exp_date: null, price: null, lotExpOptions: [] }];
            return newProducts;
        });
    };

    const handleProductChange = async (index: number, field: keyof Product, value: string | number | null) => {

        setProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            if (field === 'id' && value !== null) {
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    id: value as number,
                    lot: null,
                    exp_date: null,
                    lotExpOptions: []
                };
                // Fetch lot options after state update
                fetchLotOptions(value as number, index);
            } else {
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    [field]: value === '' ? null : value
                };
            }
            return updatedProducts;
        });
    };
    const handleLotExpChange = (index: number, value: string) => {
        const [lot, exp_date] = value.split('|');
        setProducts(prevProducts => {
            const updatedProducts = prevProducts.map((product, i) => {
                if (i === index) {
                    return { ...product, lot, exp_date };
                }
                return product;
            });
            console.log('Products after updating lot/exp:', updatedProducts);
            return updatedProducts;
        });
    };

    const handleSave = async () => {
        const newShipment = {
            distributor: distributorId as number,
            ticket_num: ticketNum,
            notes,
            products: products.map(({ lotExpOptions, ...product }) => product)
        };
        const success = await onSave(newShipment);
        if (success) {
            console.log('Shipment saved successfully, resetting form');
            setDistributorId('');
            setTicketNum('');
            setNotes('');
            setProducts([]);
            onClose();
        } else {
            console.log('Failed to save shipment');
        }
    };

    const renderClientName = () => (
        <Box mb={2}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography variant="body1">{clientName}</Typography>
        </Box>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    margin: fullScreen ? '0' : '32px',
                    width: fullScreen ? '100%' : 'calc(100% - 64px)',
                    maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)',
                },
            }}>
            <DialogTitle>Add Shipment</DialogTitle>
            <DialogContent>
                {renderClientName()}
                <FormControl fullWidth margin="dense">
                    <InputLabel>Distributor</InputLabel>
                    <Select
                        value={distributorId}
                        onChange={(e) => {
                            console.log('Distributor selected:', e.target.value);
                            setDistributorId(e.target.value as number);
                        }}>
                        {distributors.map((option: any) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    label="Ticket Number"
                    fullWidth
                    value={ticketNum}
                    onChange={(e) => {
                        console.log('Ticket number changed:', e.target.value);
                        setTicketNum(e.target.value);
                    }}
                />

                {products.map((product, index) => {
                    return (
                        <LocalizationProvider key={index} dateAdapter={AdapterDayjs}>
                            <div>
                                <FormControl fullWidth style={{marginTop: '10px', marginBottom: '10px'}}>
                                    <InputLabel>Product</InputLabel>
                                    <Select
                                        value={product.id === null ? '' : product.id}
                                        onChange={(e) => handleProductChange(index, 'id', e.target.value as number)}>
                                        {productList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {product.id !== null && (
                                    <FormControl fullWidth style={{marginBottom: '10px'}}>
                                        <InputLabel>Lot - Exp Date</InputLabel>
                                        <Select
                                            value={product.lot && product.exp_date ? `${product.lot}|${product.exp_date}` : ''}
                                            onChange={(e) => handleLotExpChange(index, e.target.value as string)}>
                                            {product.lotExpOptions.map((option, i) => (
                                                <MenuItem key={i} value={`${option.lot}|${option.exp_date}`}>
                                                    {`${option.lot} - ${formatDate(option.exp_date)}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                                    <TextField
                                        label="Exp Date"
                                        value={product.exp_date ? formatDate(product.exp_date) : ''}
                                        InputProps={{readOnly: true}}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Lot Code"
                                        value={product.lot || ''}
                                        InputProps={{readOnly: true}}
                                        fullWidth
                                    />
                                </div>
                                <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                                    <TextField
                                        label="Count"
                                        type="number"
                                        value={product.count === null ? '' : product.count}
                                        onChange={(e) => handleProductChange(index, 'count', e.target.value)}
                                        inputProps={{step: "any"}}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Price"
                                        type="number"
                                        value={product.price === null ? '' : product.price}
                                        onChange={(e) => handleProductChange(index, 'price', e.target.value)}
                                        inputProps={{step: "any"}}
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </LocalizationProvider>
                    );
                })}
                <Button onClick={handleAddProduct} style={{marginTop: '10px'}}>
                    Add Product
                </Button>

                <TextField
                    margin="dense"
                    label="Notes"
                    fullWidth
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(e) => {
                        console.log('Notes changed:', e.target.value);
                        setNotes(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddShipmentDialog;