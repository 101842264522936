import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, Button, CircularProgress, Typography, TextField } from '@mui/material';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "./Util";
import { host } from "./tableConfig";
import { AxiosError } from 'axios';

interface RecallButtonProps {
    batchId: number;
}

interface RecallNote {
    date: Date;
    note: string;
}

interface RecallData {
    id: number;
    batch: number;
    deleted: null | string;
    user_name: string;
    notes_json: Record<string, string>;
}

interface ListRecallResponse {
    result: 'success';
    data: RecallData[];
}

interface ApiErrorResponse {
    result: 'error';
    message: string;
}

const RecallButton: React.FC<RecallButtonProps> = ({ batchId }) => {
    const [isRecalled, setIsRecalled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChecking, setIsChecking] = useState<boolean>(true);
    const [recallNote, setRecallNote] = useState<string>('');
    const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
    const [recallNotes, setRecallNotes] = useState<RecallNote[]>([]);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const updateNotesFromResponse = (data: RecallData) => {
        if (!data || !data.notes_json) return;

        const notesArray = Object.entries(data.notes_json)
            .map(([date, note]) => ({
                date: new Date(date),
                note
            }))
            .sort((a, b) => b.date.getTime() - a.date.getTime());
        setRecallNotes(notesArray);
    };

    useEffect(() => {
        checkRecallStatus();
    }, [batchId]);

    const checkRecallStatus = async () => {
        try {
            const response = await axiosWrapper<ListRecallResponse | ApiErrorResponse>({
                url: `${host}/getSQLResults/list_recall/${batchId}`,
                method: 'get',
            });

            if (response.data.result === 'success') {
                if (response.data.data.length === 0) {
                    setIsRecalled(false);
                    setRecallNotes([]);
                } else {
                    setIsRecalled(true);
                    updateNotesFromResponse(response.data.data[0]);
                }
            } else {
                console.error('Error:', response.data.message);
            }
        } catch (error) {
            if ((error as AxiosError).response?.status === 404) {
                setIsRecalled(false);
                setRecallNotes([]);
            } else {
                console.error('Error checking recall status:', error);
            }
        } finally {
            setIsChecking(false);
        }
    };

    const handleRecall = async () => {
        setIsLoading(true);
        try {
            const response = await axiosWrapper<ListRecallResponse | ApiErrorResponse>({
                url: `${host}/recall`,
                method: 'post',
                data: { batch: batchId }
            });

            if (response.data.result === 'success') {
                setIsRecalled(true);
                if (response.data.data[0]) {
                    updateNotesFromResponse(response.data.data[0]);
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Error recalling batch:', error instanceof Error ? error.message : String(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddNote = async () => {
        if (!recallNote.trim()) return;

        setIsAddingNote(true);
        try {
            const response = await axiosWrapper<RecallData | ApiErrorResponse>({
                url: `${host}/update-recall`,
                method: 'post',
                data: {
                    batch: batchId,
                    notes: recallNote.trim()
                }
            });

            if ('notes_json' in response.data) {
                setRecallNote('');
                updateNotesFromResponse(response.data);
            } else {
                throw new Error('Failed to add note');
            }
        } catch (error) {
            console.error('Error adding recall note:', error instanceof Error ? error.message : String(error));
        } finally {
            setIsAddingNote(false);
        }
    };

    if (isChecking) {
        return <CircularProgress size={24} sx={{ ml: 2, mb: 1 }} />;
    }

    if (isRecalled) {
        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box
                    sx={{
                        width: '100%',
                        border: '2px solid',
                        borderColor: 'error.main',
                        borderRadius: 2,
                        pt: 2,
                        pb: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                        Batch Recalled
                    </Typography>
                </Box>

                {recallNotes.length > 0 && (
                    <Box sx={{
                        width: '90%',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 2,
                        p: 2,
                        bgcolor: 'grey.50'
                    }}>
                        <Typography sx={{ fontWeight: 600, mb: 1 }}>Recall Notes:</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {recallNotes.map(({ date, note }, index) => (
                                <Box key={index} sx={{
                                    borderBottom: index < recallNotes.length - 1 ? 1 : 0,
                                    borderColor: 'divider',
                                    pb: 1
                                }}>
                                    <Typography sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>
                                        {date.toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric'
                                        })}
                                    </Typography>
                                    <Typography sx={{ mt: 0.5 }}>
                                        {note}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Add a note about this recall"
                        value={recallNote}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setRecallNote(e.target.value)}
                        disabled={isAddingNote}
                        multiline
                        rows={3}
                    />
                    <Button
                        variant="contained"
                        onClick={handleAddNote}
                        disabled={isAddingNote || !recallNote.trim()}
                        sx={{
                            width: '100%',
                            bgcolor: 'primary.main',
                            color: 'white',
                            '&:hover': {
                                bgcolor: 'primary.dark'
                            }
                        }}
                    >
                        {isAddingNote ? <CircularProgress size={24} /> : 'Add Recall Note'}
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Button
            variant="contained"
            color="error"
            onClick={handleRecall}
            disabled={isLoading}
            fullWidth
            sx={{ p: 2 }}
        >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Recall Batch'}
        </Button>
    );
};

export default RecallButton;