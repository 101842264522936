import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Select, MenuItem, InputLabel, FormControl, Button,
    useMediaQuery, useTheme, Typography, Box
} from '@mui/material';
import {host} from './tableConfig'
import {createAxiosWrapper} from "./Util";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import ReadOnlyShipping from "./ReadOnlyShipping";
import {formatDate} from "./Util";

interface EditShipmentDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (shipment: any) => Promise<boolean>;
    shipmentId: number;
    clientName: string;
}

const EditShipmentDialog: React.FC<any> = ({
              open, onClose,
              onSave, shipmentId, clientName }) =>
{
    const [name, setName] = useState('');
    const [clientId, setClientId] = useState<number | ''>('');
    const [shipment, setShipment] = useState('');
    const [notes, setNotes] = useState('');
    const [setup, setSetup] = useState('');
    const [products, setProducts] = useState<{ id: number | null, count: number | null }[]>([]);
    const [productList, setProductList] = useState<any[]>([]);
    const [readOnly, setReadOnly] = useState(true);
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (open && shipmentId !== null) {
            fetchShipmentData();
            fetchProductOptions();
        }
    }, [open, shipmentId]);

    const fetchShipmentData = async () => {
        try {
            console.log(`Fetching shipment data for ID: ${shipmentId}`); // Debugging log
            const response = await axiosWrapper({url:`${host}/shipping/${shipmentId}`});
            const data = response.data;
            console.log('Fetched shipment data:', data); // Debugging log
            if (data.result === 'success' && data.data) {
                const shipment = data.data[0];
                setShipment(shipment || '');
                setNotes(shipment.notes || '');
                setProducts(shipment.products || []);
            } else {
                console.error('Failed to fetch shipment data:', data.message);
                setProducts([]);    // Ensure it's always an array
            }
        } catch (error) {
            console.error('Error fetching shipment data:', error);
            setProducts([]);    // Set to empty array on error
        }
    };


    const fetchProductOptions = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/product`});
            const data = response.data;
            if (data.result === 'success') {
                setProductList(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching product options:', error);
        }
    };

    const handleAddProduct = () => {
        setProducts([...products, { id: null, count: null }]);
    };

    const handleProductChange = (index: number, field: 'id' | 'count', value: string | number) => {
        const updatedProducts = products.map((product, i) => {
            if (i === index) {
                return {
                    ...product,
                    [field]: value === '' ? null : Number(value)
                };
            }
            return product;
        });
        setProducts(updatedProducts);
    };

    const handleSave = async () => {
        const updatedShipment: any = {
            id: shipmentId,
            name,
            clientId: clientId as number,
            shipment,
            notes,
            setup,
            products
        };
        const success = await onSave(updatedShipment);
        if (success) {
            onClose();
            setReadOnly(true);
        }
    };

    const renderClientName = () => (
        <Box mb={2}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography variant="body1">{clientName}</Typography>
        </Box>
    );

    return (
        <>
            {readOnly ? (
                <ReadOnlyShipping
                    open={open}
                    onClose={onClose}
                    onEdit={() => setReadOnly(false)}
                    shipment={shipment}
                    showButtons={true}
                    clientName={clientName}  // A
                />
            ) : (
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullScreen={fullScreen}
                    maxWidth="md"
                    fullWidth
                    PaperProps={{
                        style: {
                            margin: fullScreen ? '0' : '32px',
                            width: fullScreen ? '100%' : 'calc(100% - 64px)',
                            maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)',
                        },
                    }}
                >
                    <DialogTitle>Edit Shipment</DialogTitle>
                    <DialogContent>
                        {renderClientName()}
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        {/* Product Section */}
                        <Typography variant="h6" sx={{ marginTop: '10px' }}>
                            Products
                        </Typography>
                        {products.map((product, index) => (
                            <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel>Product</InputLabel>
                                    <Select
                                        value={product.id === null ? '' : product.id}
                                        onChange={(e) => handleProductChange(index, 'id', e.target.value as number)}
                                    >
                                        {productList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Count"
                                    type="number"
                                    value={product.count === null ? '' : product.count}
                                    onChange={(e) => handleProductChange(index, 'count', e.target.value)}
                                    inputProps={{ step: "any" }}
                                />
                            </div>
                        ))}
                        <Button onClick={handleAddProduct} >
                            Add Product
                        </Button>

                        <TextField
                            sx={{ marginTop: '10px' }}
                            margin="dense"
                            label="Notes"
                            fullWidth
                            multiline
                            rows={4}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setReadOnly(true); onClose()}}>Cancel</Button>
                        <Button onClick={()=>{handleSave()}}>Save</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default EditShipmentDialog;
