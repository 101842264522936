import React from 'react';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ListIcon from '@mui/icons-material/List';
import ArticleIcon from '@mui/icons-material/Article';
import ViewListIcon from '@mui/icons-material/ViewList';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoginIcon from '@mui/icons-material/Login';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import {BarChart, PhotoCamera} from '@mui/icons-material';
import { Category } from '@mui/icons-material';
import { ReactComponent as AppleIcon } from './images/icons/apple.svg';
import { ReactComponent as ApplePieIcon } from './images/icons/apple-pie.svg';
import { LibraryBooks } from '@mui/icons-material';

import PanelOne from './PanelOne';
import PanelTwo from "./PanelTwo";
import PanelThree from './Batch/PanelThree';
import TablePanel from './TablePanel';
import MarkdownDisplay from "./MarkdownDisplay";
import tableConfig from "./tableConfig";
import ScheduledProcessTable from "./ScheduledProcessTable";
import AccountInfo from "./AccountInfo";
import Recall from "./Recall";
import Shipping from "./Shipping";
import InventoryManagement from "./Inventory";
import PhotoCaptureUpload from "./PhotoCapture";
import FlowChartMenu from "./FlowChartMenu";
import { chart as lotCodeChart } from "./LotCodeChartDef";
import { chart as productionChart } from "./ProductionFlowChartDef";
import InventoryMenu from "./InventoryMenu";
import InMenu from "./InMenu";
import PhotoMenu from "./PhotoMenu";
import TablePanelPlus from "./TablePanelPlus";
import TablePanelFiltered from "./TablePanelFiltered";
import BatchesByDate from "./BatchesByDate";
import InvoiceTable from "./InvoiceTable";
import CatalogPanel from "./CatalogPanel";
import PestControl from "./PestControl/PestControl";

export interface MenuItem {
    icon?: React.ReactElement
    title: string
    component: React.ReactElement
    subItems?: MenuItem[]
}

const getMenuItems = (handleNodeClick: (nodeName: string) => void, handleMenuAction: (action: string) => void): MenuItem[] => [

    { icon: <ChecklistIcon />, title: "Inspections", component: <PanelOne /> },
    { icon: <PhotoCamera />, title: "Photos", component: <PhotoMenu onAction={handleMenuAction}/> },
    { icon: <LoginIcon />, title: "In", component: <InMenu onAction={handleMenuAction}/> },
    { icon: <LogoutIcon />, title: "Out", component: <Shipping /> },
    { icon: <ListIcon />, title: "Batches", component: <PanelThree /> },
    { icon: <Category />, title: "Inventory", component: <InventoryMenu onAction={handleMenuAction}/> },
    { icon: <LibraryBooks />, title: "Logs", component: <TablePanel byClient={true} columns={tableConfig.logs.columns} itemTitle="Log" endpoints={tableConfig.logs.endpoints} showTimestamp={true} /> },
    { icon: <BarChart />, title: "Review", component: <PanelTwo /> },
    {  title: "Logout", component: <div /> },
    {
        icon: <LocalShippingIcon />,
        title: "Ship+Receive",
        component: <div />, // Placeholder component
        subItems: [
            { icon: <LocalShippingIcon />, title: "Deliveries", component: <TablePanel columns={tableConfig.deliveries.columns} itemTitle="Deliveries" endpoints={tableConfig.deliveries.endpoints} showTimestamp={true} /> },
            { title: "Ingredient Receiving", component: <TablePanelPlus columns={tableConfig.ingredientReceiving.columns} itemTitle="Ingredient Receiving" endpoints={tableConfig.ingredientReceiving.endpoints} showTimestamp={true} /> },
            { title: "Packaging Receiving", component: <TablePanelPlus columns={tableConfig.packagingReceiving.columns} itemTitle="Packaging Receiving" endpoints={tableConfig.packagingReceiving.endpoints} showTimestamp={true} /> },
            { icon: <AppleIcon />, title: "Ingredients Inventory", component: <InventoryManagement inventoryType="ingredient" /> },
            { icon: <ApplePieIcon />, title: "Product Inventory", component: <InventoryManagement inventoryType="product" /> },
            { title: "Shipping", component: <Shipping />  },
        ]
    },
    {
        icon: <ViewListIcon />,
        title: "Lists",
        component: <div />, // Placeholder component
        subItems: [
            { title: "Brands", component: <TablePanelPlus columns={tableConfig.brands.columns} itemTitle="Brand" endpoints={tableConfig.brands.endpoints} /> },
            { title: "Clients", component: <TablePanel columns={tableConfig.clients.columns} itemTitle="Clients" endpoints={tableConfig.clients.endpoints} /> },
            { title: "Distributors", component: <TablePanel columns={tableConfig.distributors.columns} itemTitle="Distributor" endpoints={tableConfig.distributors.endpoints} /> },
            { title: "Equipment", component: <TablePanelPlus columns={tableConfig.equipment.columns} itemTitle="Equipment" endpoints={tableConfig.equipment.endpoints} /> },
            { title: "Ingredients", component: <TablePanel columns={tableConfig.ingredients.columns} itemTitle="Ingredient" endpoints={tableConfig.ingredients.endpoints} /> },
            { title: "Ingredient Suppliers", component: <TablePanel columns={tableConfig.ingredientSuppliers.columns} itemTitle="Ingredient Supplier" endpoints={tableConfig.ingredientSuppliers.endpoints} /> },
            { title: "Inspection Items", component: <TablePanelFiltered columns={tableConfig.list_items.columns} itemTitle="Inspection Item" endpoints={tableConfig.list_items.endpoints} filterBy={{type: 'list', title: 'Inspection Type', col: 'list_id'}} /> },
            { title: "Inspection Types", component: <TablePanel byClient={true} columns={tableConfig.lists.columns} itemTitle="Inspection Type" endpoints={tableConfig.lists.endpoints} /> },
            { title: "Products", component: <TablePanelPlus columns={tableConfig.products.columns} itemTitle="Product" endpoints={tableConfig.products.endpoints} /> },
            { title: "Product Categories", component: <TablePanelPlus columns={tableConfig.productCategories.columns} itemTitle="Product Category" endpoints={tableConfig.productCategories.endpoints} /> },
            { title: "Packaging", component: <TablePanel columns={tableConfig.packaging.columns} itemTitle="Packaging" endpoints={tableConfig.packaging.endpoints} /> },
            { title: "Packaging Suppliers", component: <TablePanel columns={tableConfig.packagingSuppliers.columns} itemTitle="Packaging Supplier" endpoints={tableConfig.packagingSuppliers.endpoints} /> },
            { title: "Scheduled Processes", component: <ScheduledProcessTable /> },
        ]

    },
    {
        icon: <ArticleIcon />,
        title: "Documents",
        component: <div />, // Placeholder component
        subItems: [
            { title: "Pest Control", component: <PestControl /> },
        ]
    },
    {
        icon: <MiscellaneousServicesIcon />,
        title: "Other",
        component: <div />, // Placeholder component
        subItems: [
            { icon: <AccountCircleIcon />, title: "Account", component: <AccountInfo /> },
            { title: "Batches By Date", component: <BatchesByDate /> },
            { title: "Catalog", component: <CatalogPanel /> },
            { icon: <WarningAmberOutlinedIcon />, title: "Incidents", component: <TablePanel columns={tableConfig.incidents.columns} itemTitle="Incident" endpoints={tableConfig.incidents.endpoints} showTimestamp={true} /> },
            { title: "Recall", component: <Recall /> },
            {
                icon: <AccountTreeIcon />,
                title: "Process Flow Menu",
                component: <FlowChartMenu onNodeClick={handleNodeClick} chartDefinition={productionChart} />
            },
            {
                icon: <AccountTreeIcon />,
                title: "Lot Tracking Menu",
                component: <FlowChartMenu onNodeClick={handleNodeClick} chartDefinition={lotCodeChart} />
            },
            { icon: <PeopleIcon />, title: "Visitors", component: <TablePanel columns={tableConfig.visitors.columns} itemTitle="Visitor" endpoints={tableConfig.visitors.endpoints} showTimestamp={true} /> },
            { icon: <HelpIcon />, title: "Help Center", component: <MarkdownDisplay fileName="help"/> },
            { title: "Photo Capture", component: <PhotoCaptureUpload /> },
        ]
    },
    {
        icon: <AccountBoxIcon />,
        title: "Manage Clients",
        component: <div />, // Placeholder component
        subItems: [
            { title: "Clients", component: <TablePanel columns={tableConfig.clients.columns} itemTitle="Clients" endpoints={tableConfig.clients.endpoints} /> },
            { title: "Invoices", component: <InvoiceTable /> },
        ]
    },

]
export default getMenuItems;