import React, { useState, useCallback, useRef, useEffect, forwardRef } from 'react';
import Webcam, { WebcamProps } from 'react-webcam';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledWebcamContainer = styled(Box)({
    width: '100%',
    maxWidth: '500px',
    marginBottom: '20px',
    position: 'relative',
    '&::before': {
        content: '""',
        display: 'block',
        paddingTop: '75%', // 4:3 Aspect Ratio
    },
});

const WebcamComponent = forwardRef<Webcam, WebcamProps>((props, ref) =>
    <Webcam {...props} ref={ref} />
);

const StyledWebcam = styled(WebcamComponent)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

interface CameraCaptureProps {
    onCapture: (imageSrc: string) => void;
}

const CameraCapture: React.FC<CameraCaptureProps> = ({ onCapture }) => {
    const webcamRef = useRef<Webcam>(null);
    const [videoConstraints, setVideoConstraints] = useState({
        facingMode: "environment",
        width: { ideal: 4096 },
        height: { ideal: 2160 }
    });

    useEffect(() => {
        const updateVideoConstraints = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setVideoConstraints({
                facingMode: "environment",
                width: { ideal: isPortrait ? 2160 : 4096 },
                height: { ideal: isPortrait ? 4096 : 2160 }
            });
        };

        updateVideoConstraints();
        window.addEventListener('resize', updateVideoConstraints);
        return () => window.removeEventListener('resize', updateVideoConstraints);
    }, []);

    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set canvas size to match the original image size
                canvas.width = img.width;
                canvas.height = img.height;

                if (ctx) {
                    // Draw the image without any transformations
                    ctx.drawImage(img, 0, 0);

                    // Convert to blob to get file size
                    canvas.toBlob((blob) => {
                        if (blob) {
                            console.log(`Captured image size: ${blob.size / 1024 / 1024} MB`);
                        }
                        onCapture(canvas.toDataURL('image/jpeg', 0.95));
                    }, 'image/jpeg', 0.95);
                }
            };
            img.src = imageSrc;
        }
    }, [onCapture]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <StyledWebcamContainer>
                <StyledWebcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    disablePictureInPicture={false}
                    forceScreenshotSourceSize={true}
                    imageSmoothing={true}
                    mirrored={false}
                    screenshotQuality={1}
                    onUserMedia={() => console.log('Camera is ready')}
                    onUserMediaError={(err) => console.error('Camera error:', err)}
                />
            </StyledWebcamContainer>
            <Button variant="contained" color="primary" onClick={capturePhoto}>
                Capture
            </Button>
        </Box>
    );
};

export default CameraCapture;