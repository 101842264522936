import React from "react";
import { Box } from '@mui/material';
import { ReactComponent as AppleIcon } from './images/icons/apple.svg';
import { ReactComponent as ApplePieIcon } from './images/icons/apple-pie.svg';
import MenuActionButton from './MenuActionButton';

interface InventoryMenuProps {
    onAction: (action: string) => void;
}

const InventoryMenu: React.FC<InventoryMenuProps> = ({ onAction }) => {
    const iconStyle = { fontSize: '2rem' };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            maxWidth: '300px',
            margin: '60px auto',
        }}>
            <MenuActionButton
                icon={<AppleIcon  />}
                title="Ingredients Inventory"
                action="Ship+Receive/Ingredients Inventory"
                onAction={onAction}
            />
            <MenuActionButton
                icon={<ApplePieIcon />}
                title="Product Inventory"
                action="Ship+Receive/Product Inventory"
                onAction={onAction}
            />
        </Box>
    );
};
export default InventoryMenu;