import React from 'react';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import backgroundImageLarge from './images/splash-large.png';
import backgroundImageMobile from './images/splash-mobi-2.png'; // Assuming you have this image

interface LoginPanelProps {
    login: () => void;
    register: () => void;
}

const LoginPanel: React.FC<LoginPanelProps> = ({ login, register }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box>
        <Box
            sx={{
                height: { xs: '100vh', sm: '800px' },
                width: { xs: '100%', sm: '800px' },
                margin: { xs: 0, sm: '30px' },
                //border: '1px solid green',
                backgroundImage: {
                    xs: `url(${backgroundImageMobile})`,
                    sm: `url(${backgroundImageLarge})`,
                },
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: '340px', sm: '650px' },
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '80%',
                    maxWidth: '300px',
                }}
            >
                <Button
                    onClick={login}
                    type="button"
                    sx={{
                        mb: 2,
                        width: '140px',
                        color: 'black',
                        //fontWeight: 'bold',
                        fontSize: '1.2rem',
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        '&:hover': {
                            backgroundColor: 'lightgray',
                        },
                    }}
                >
                    Log In
                </Button>
                <Button
                    onClick={register}
                    type="button"
                    fullWidth
                    sx={{
                        display: 'none',
                        mb: 2,
                        color: 'black',
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'lightgray',
                        },
                    }}
                >
                    Register
                </Button>
            </Box>
        </Box>
        </Box>
    );
};

export default LoginPanel;