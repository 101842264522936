import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ChartContextType {
    chartSvg: string;
    setChartSvg: (svg: string) => void;
}

const ChartContext = createContext<ChartContextType | undefined>(undefined);

export const ChartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [chartSvg, setChartSvg] = useState('');

    return (
        <ChartContext.Provider value={{ chartSvg, setChartSvg }}>
            {children}
        </ChartContext.Provider>
    );
};

export const useChart = () => {
    const context = useContext(ChartContext);
    if (context === undefined) {
        throw new Error('useChart must be used within a ChartProvider');
    }
    return context;
};