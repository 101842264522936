export const chart = `
graph TD
    M[Ingredients<br>List] -.-> A
    N[Packaging<br>List] -.-> B
    O[Brands<br>List] -.-> P
    P[Products<br>List] -.-> I
    A[Ingredient<br>Supplier List] -.-> C
    B[Packaging<br>Supplier List] -.-> C
    C[Deliveries] --> D[Ingredients<br>Receiving]
    C --> E[Packaging<br>Receiving]
    D --> F[Ingredients<br>Inventory]
    E --> G[Packaging<br>Inventory]
    F --> H[Batches]
    G --> H
    I[Scheduled<br>Processes] --> H
    H --> J[Product<br>Inventory]
    J --> K[Shipments]
    K -.-> L[Recalls]

    class A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q clickable;

    classDef clickable cursor:pointer;
    classDef default fill:#4CAF50,stroke:#333,stroke-width:2px,color:white;
    classDef list fill:#2196F3,stroke:#333,stroke-width:2px,color:white;
    classDef purple fill:#9C27B0,stroke:#333,stroke-width:2px,color:white;

    class M,N,A,B,O,P,Q list;
    class C,K purple;
      `;
