// InspectionTable.tsx
import React, { useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    IconButton,
    Tooltip,
    Typography,
    Box,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import { host } from "../tableConfig";
import { Inspection } from './types';

interface InspectionTableProps {
    inspections: Inspection[] | null;
    orderBy: string;
    order: 'asc' | 'desc';
    onSort: (property: string) => void;  // Updated to accept property parameter
    defaultSort?: string;  // Add default sort property
}

export const InspectionTable: React.FC<InspectionTableProps> = ({
                                                                    inspections,
                                                                    orderBy,
                                                                    order,
                                                                    onSort,
                                                                    defaultSort = 'inspection_date'  // Default to inspection_date
                                                                }) => {
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    // Set initial sort on mount
    useEffect(() => {
        if (orderBy === '') {
            onSort(defaultSort);
        }
    }, [orderBy, onSort, defaultSort]);

    const handleDownload = async (inspection: Inspection) => {
        if (!inspection.name) {
            console.error('No filename available for download');
            return;
        }

        try {
            const response = await axiosWrapper({
                url: `${host}/pest-control-docs/download/${inspection.facility_num}/${inspection.name}`,
                method: 'get',
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', inspection.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    if (!inspections || inspections.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 1,
                    margin: '20px 0 40px 0'
                }}
            >
                <Typography variant="h6" color="text.secondary">
                    No inspections found
                </Typography>
            </Box>
        );
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                margin: '20px 0',
                marginBottom: '40px'
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'inspection_date'}
                                direction={orderBy === 'inspection_date' ? order : 'desc'}
                                onClick={() => onSort('inspection_date')}
                            >
                                Inspection Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Document</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inspections.map((inspection) => (
                        <TableRow key={inspection.id}>
                            <TableCell>
                                {new Date(inspection.inspection_date).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                                {inspection.name && (
                                    <Tooltip title="Download Document">
                                        <IconButton
                                            onClick={() => handleDownload(inspection)}
                                            size="small"
                                        >
                                            <DescriptionIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        color: 'primary.main'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InspectionTable;