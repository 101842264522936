import axios, { AxiosResponse } from 'axios';
import {Batch} from "./types";

export const createAxiosWrapper = (getToken: any) => {
    return async <T = any>(config: any): Promise<AxiosResponse<T>> => {
        const accessToken = await getToken();
        config.headers = config.headers || {}
        config.headers.Authorization = `Bearer ${accessToken}`
        return axios<T>(config);
    };
};

export const getPriceAsNumber = (priceString: string) => {
    if (!priceString) return 0;

    // Remove currency symbol, spaces and convert commas to dots
    const cleaned = priceString
        .replace(/[$€£¥]/g, '')
        .replace(/\s/g, '')
        .replace(',', '.');

    // Convert to float
    const number = parseFloat(cleaned);

    // Return 0 if not a valid number
    return isNaN(number) ? 0 : number;
}

export const isValidDate = (dateString: string | number | Date): boolean => {
    if (!dateString) return false;
    const date = new Date(dateString);
    return !isNaN(date.getTime());
};
export function formatCurrency(amount: number | string): string {
    // Convert string to number if necessary
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

    // Check if the conversion resulted in a valid number
    if (isNaN(numericAmount)) {
        throw new Error('Invalid input: the amount must be a valid number or numeric string');
    }

    const absAmount = Math.abs(numericAmount);
    const formattedAmount = absAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return numericAmount >= 0 ? formattedAmount : `-${formattedAmount}`;
}

export const calculateTotalAmount = (batches: Batch[]): number => {
    return batches.reduce((total, batch) => {
        const batchTotal = batch.products?.reduce((batchSum, product) => {
            const toll = product.toll ? parseFloat(product.toll.replace('$', '')) : 0;
            const count = product.count ?? 0; // Use 0 if count is null
            return batchSum + (count * toll);
        }, 0);
        return total + (batchTotal || 0);
    }, 0);
};
export function formatDate(
    dateString: string | number | Date | null | undefined,
    formatType: 'full' | 'short' = 'full'
) {
    if (!dateString) { return ''; }
    if (!isValidDate(dateString)) return '';

    // Create a new Date object
    const date = new Date(dateString);

    // Get user's timezone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Format the date
    const formattedDate = date.toLocaleString('en-US', {
        timeZone: userTimeZone,
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
    });

    // Split the formatted date
    const [month, day, year] = formattedDate.split('/');

    // Return the appropriate format based on the formatType parameter
    if (formatType === 'short') {
        return `${month}/${day}`;
    } else {
        return `${month}/${day}/${year}`;
    }
}

/**
 * Custom error type for code generation errors
 */
class CodeGenerationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'CodeGenerationError';
    }
}

/**
 * Configuration interface for the code generator
 */
interface CodeGeneratorConfig {
    letters: string;
    length: number;
    maxNumber: number;
}

/**
 * Default configuration for the code generator
 */
const DEFAULT_CONFIG: CodeGeneratorConfig = {
    letters: 'BCDFGHJKLMNPQRSTVWXYZ', // Consonants excluding I and O
    length: 5,
    maxNumber: 9999
};

/**
 * Converts a number to a unique letter code
 * @param number - A positive integer less than 10000
 * @param config - Optional configuration for the code generator
 * @returns A unique letter code
 * @throws {CodeGenerationError} If the input is invalid
 */
export function numberToCode(
    number: number,
    config: Partial<CodeGeneratorConfig> = {}
): string {
    // Merge default config with provided config
    const finalConfig: CodeGeneratorConfig = {
        ...DEFAULT_CONFIG,
        ...config
    };

    // Input validation
    if (!Number.isInteger(number)) {
        throw new CodeGenerationError('Input must be an integer');
    }

    if (number < 0 || number >= finalConfig.maxNumber) {
        throw new CodeGenerationError(
            `Number must be between 0 and ${finalConfig.maxNumber - 1}`
        );
    }

    // Initialize result array with default letter
    const result: string[] = new Array(finalConfig.length).fill('A');

    // Convert number to base-N (where N is the length of our letter set)
    let temp = number;
    for (let i = finalConfig.length - 1; i >= 0; i--) {
        if (temp > 0) {
            const index = temp % finalConfig.letters.length;
            result[i] = finalConfig.letters[index];
            temp = Math.floor(temp / finalConfig.letters.length);
        }
    }

    return result.join('');
}

/**
 * Attempts to convert a number to a code and handles errors
 * @param number - A positive integer less than 10000
 * @returns An object containing either the code or an error
 */
export function tryNumberToCode(
    number: number
): { success: true; code: string } | { success: false; code: number; error: string } {
    try {
        const code = numberToCode(number);
        return { success: true, code };
    } catch (error) {
        return {
            success: false,
            code: number,
            error: error instanceof Error ? error.message : 'Unknown error occurred'
        };
    }
}