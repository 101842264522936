import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Snackbar, Box, Paper, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { PhotoCamera, Upload, Refresh } from '@mui/icons-material';
import axios from 'axios';
import { host } from './tableConfig';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "./Util";
import CameraCapture from './CameraCapture';
import useUserStore from "./store/userStore";

const StyledImg = styled('img')({
    width: '100%',
    maxWidth: '500px',
    marginBottom: '20px',
});

const PlaceholderPanel = styled(Paper)({
    width: '100%',
    maxWidth: '500px',
    height: '375px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    backgroundColor: '#e0e0e0',
});

const PhotoCaptureUpload: React.FC = () => {
    const [albums, setAlbums] = useState<any[]>([]);
    const [selectedAlbum, setSelectedAlbum] = useState<string>('');
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [text, setText] = useState('');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    // Get user and clients from the store
    const { user } = useUserStore();

    useEffect(() => {
        fetchAlbums();
    }, []);

    const fetchAlbums = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/getSQLResults/list_albums` });
            const data = response.data;
            if (data.result === 'success') {
                setAlbums(data.data);
            }
        } catch (error) {
            console.error('Error fetching albums:', error);
        }
    };

    const startCamera = () => {
        setIsCameraActive(true);
    };

    const handleCapture = (capturedImage: string) => {
        setImageSrc(capturedImage);
        setIsCameraActive(false);
    };

    const uploadPhoto = async () => {
        if (!imageSrc || !selectedAlbum) return;

        setIsUploading(true);
        try {
            const response = await fetch(imageSrc);
            const blob = await response.blob();
            const file = new File([blob], "photo.jpg", { type: "image/jpeg" });

            const formData = new FormData();
            formData.append('photo', file);
            formData.append('text', text);
            formData.append('albumId', selectedAlbum);

            // @ts-ignore
            const accessToken = await getToken();
            const result = await axios.post(`${host}/upload?clientId=${user?.clientId || 0}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setSnackbar({ open: true, message: 'Photo and text uploaded successfully' });
            console.log('Upload successful:', result.data);
        } catch (error) {
            console.error('Error uploading photo and text:', error);
            setSnackbar({ open: true, message: 'Error uploading photo and text' });
        } finally {
            setIsUploading(false);
        }
    };

    const resetCapture = () => {
        setImageSrc(null);
        setIsCameraActive(false);
        setText('');
        setSelectedAlbum('');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, mb: 20 }}>
            {!isCameraActive && !imageSrc ? (
                <PlaceholderPanel>
                    Click "Start Camera" to begin
                </PlaceholderPanel>
            ) : isCameraActive ? (
                <CameraCapture onCapture={handleCapture} />
            ) : imageSrc ? (
                <StyledImg src={imageSrc} alt="Captured" />
            ) : null}

            {imageSrc && (
                <>
                    <FormControl fullWidth sx={{ maxWidth: '500px', mb: 2 }}>
                        <InputLabel id="album-select-label">Choose Album</InputLabel>
                        <Select
                            labelId="album-select-label"
                            value={selectedAlbum}
                            label="Choose Album"
                            onChange={(e) => setSelectedAlbum(e.target.value as string)}
                        >
                            {albums.map((album) => (
                                <MenuItem key={album.id} value={album.id}>{album.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Add a description"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        margin="normal"
                        sx={{ maxWidth: '500px', mb: 2 }}
                    />
                </>
            )}

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                {!imageSrc ? (
                    <Button
                        variant="contained"
                        startIcon={<PhotoCamera />}
                        onClick={startCamera}
                    >
                        Start Camera
                    </Button>
                ) : (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Upload />}
                            onClick={uploadPhoto}
                            disabled={isUploading || !selectedAlbum}
                        >
                            {isUploading ? <CircularProgress size={24} /> : 'Upload'}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<Refresh />}
                            onClick={resetCapture}
                        >
                            Retake
                        </Button>
                    </>
                )}
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
            />
        </Box>
    );
};

export default PhotoCaptureUpload;