
import React, { useState, useEffect } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import ItemDialog from './ItemDialog';
import { createAxiosWrapper } from './Util';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { host } from './tableConfig'
import ItemDialogPlus from "./ItemDialogPlus";
import ItemDialogFiltered from "./ItemDialogFiltered";
import useUserStore from "./store/userStore";

export interface Column {
    id: string;
    label: string;
    rows?: number;
    type?: string;
    options?: readonly string[];
    display?: boolean;
    endpoint?: string;
}

interface Item {
    id: number;
    [key: string]: any;
}

interface Client {
    id: number;
    name: string;
}

interface Endpoints {
    get: string;
    post: string;
    put: string;
}

interface TablePanelProps {
    columns: readonly Column[];
    itemTitle: string;
    endpoints: Endpoints;
    showTimestamp?: boolean;
    timestampField?: string;
    filterBy: any;
}

type Order = 'asc' | 'desc';

const TablePanelFiltered: React.FC<TablePanelProps> = ({
                                                   columns,
                                                   itemTitle,
                                                   endpoints,
                                                   showTimestamp = false,
                                                   timestampField = 'timestamp',
                                                   filterBy
                                               }) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<Item[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedItem, setSelectedItem] = useState<Item | undefined>();
    const [mode, setMode] = useState<'add' | 'view' | 'edit'>('add');
    const [selectOptions, setSelectOptions] = useState<{ [key: string]: any[] }>({});
    const [orderBy, setOrderBy] = useState<string>('');
    const [order, setOrder] = useState<Order>('asc');
    const { getToken } = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);
    const [selectedClientName, setSelectedClientName] = useState<string>('');

    // Get user and clients from the store
    const { user } = useUserStore();

    let displayColumns = columns.filter(column => column.display);
    if (showTimestamp) {
        displayColumns = [
            { id: 'formattedDate', label: 'Date', display: true },
            ...displayColumns
        ];
    }

    useEffect(() => {
        fetchClients();
        fetchSelectOptions();
    }, []);

    useEffect(() => {
        if (selectedClientId) {
            fetchItems();
        }
    }, [selectedClientId]);

    const fetchClients = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/${filterBy.type}?clientId=${user?.clientId || 0}` });
            if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                setClients(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching filterBy:', error);
        }
    };

    const fetchItems = async () => {
        if (!selectedClientId) return;
        try {
            const response = await axiosWrapper({ url: `${endpoints.get}?filter=${selectedClientId}&filterType=${filterBy.col}` })
            const formattedItems = response.data.data.map((item: Item) => ({
                ...item,
                formattedDate: item[timestampField] ? formatDate(new Date(item[timestampField])) : ''
            }));
            setItems(formattedItems);
        } catch (error) {
            console.error(`Error fetching ${itemTitle}s:`, error);
        }
    };

    const fetchSelectOptions = async () => {
        for (const column of columns) {
            if (column.type === 'select' && column.endpoint) {
                try {
                    const response = await axiosWrapper({ url: column.endpoint });
                    if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                        setSelectOptions(prev => ({
                            ...prev,
                            [column.id]: response.data.data
                        }));
                    }
                } catch (error) {
                    console.error(`Error fetching options for ${column.id}:`, error);
                }
            }
        }
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    const handleOpen = () => {
        setMode('add');
        setSelectedItem(undefined);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedItem(undefined);
        setMode('add');
    };

    const handleSave = async (newItem: Omit<Item, 'id'>) => {
        try {
            const data = { ...newItem };
            data[filterBy.col] = selectedClientId;
            if (mode === 'add') {
                await axiosWrapper({ url: endpoints.post, data, method: 'post' });
            } else if (mode === 'edit' && selectedItem) {
                await axiosWrapper({ url: `${endpoints.put}/${selectedItem.id}`, method: 'put', data });
            }
            await fetchItems();
            handleClose();
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
            throw error;
        }
    };
    const handleDelete = async (item: Omit<Item, 'id'>) => {
        try {
            // @ts-ignore
            await axiosWrapper({ url: `${endpoints.put}/${item.id}`, method: 'delete' });
            await fetchItems();
            handleClose();
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
            throw error;
        }
    };

    const handleRowClick = (item: Item) => {
        setSelectedItem(item);
        setMode('view');
        setOpen(true);
    };

    const handleModeChange = (newMode: 'add' | 'view' | 'edit') => {
        setMode(newMode);
    };

    const getDisplayValue = (item: Item, column: Column) => {
        if (column.type === 'radio' && column.options) {
            const optionIndex = Number(item[column.id]);
            return column.options[optionIndex] || item[column.id];
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find(opt => opt.id.toString() === item[column.id]?.toString());
            return option ? (option.brand_name || option.name || item[column.id]) : item[column.id];
        }
        return item[column.id];
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedItems = React.useMemo(() => {
        const comparator = (a: Item, b: Item) => {
            const column = displayColumns.find(col => col.id === orderBy);
            if (!column) return 0;

            const aValue = getDisplayValue(a, column);
            const bValue = getDisplayValue(b, column);

            if (bValue < aValue) {
                return order === 'asc' ? 1 : -1;
            }
            if (bValue > aValue) {
                return order === 'asc' ? -1 : 1;
            }
            return 0;
        };

        return orderBy ? [...items].sort(comparator) : items;
    }, [items, order, orderBy, displayColumns]);

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };
    const getCellAlignment = (columnLabel: string) => {
        return columnLabel === 'Count' || columnLabel === 'Price' ? 'right' : 'left';
    };

    return (
        <Box sx={{ maxWidth: '600px', margin: '0 auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="client-select-label">{filterBy.title}</InputLabel>
                    <Select
                        labelId="client-select-label"
                        id="client-select"
                        value={selectedClientId}
                        label="{filterBy.title}"
                        sx={{ width: '250px' }}
                        onChange={handleClientChange}>
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedClientId && (
                    <Button
                        variant="contained"
                        onClick={handleOpen}
                        sx={{
                            marginTop: '10px'
                        }}
                    >
                        Add {itemTitle}
                    </Button>
                )}
            </Box>
            {selectedClientId && (
                <TableContainer sx={{ mb: '30px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {displayColumns.map((column) => (
                                    <TableCell key={column.id}
                                               align={getCellAlignment(column.label)}
                                               sx={{ fontWeight: 'bold' }}>
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedItems.map((item) => (
                                <TableRow key={item.id} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }}>
                                    {displayColumns.map((column) => (
                                        <TableCell
                                            align={getCellAlignment(column.label)}
                                            key={column.id}>
                                            {getDisplayValue(item, column)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <ItemDialogFiltered
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                onDelete={handleDelete}
                item={selectedItem}
                mode={mode}
                onModeChange={handleModeChange}
                columns={columns.filter(column => column.id !== filterBy.col)}
                itemTitle={itemTitle}
                clientName={selectedClientName}
                filterBy={filterBy}
            />
        </Box>
    );
};

export default TablePanelFiltered