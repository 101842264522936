import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { Auth0Provider } from '@auth0/auth0-react';
import {KindeProvider} from "@kinde-oss/kinde-auth-react";
import {create} from "zustand";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const useStore = create((set) => ({
    user: {
        name: '',
        email: '',
        role: ''
    },
    setUser: (userData:any) => set((state:any) => ({ user: { ...state.user, ...userData } })),
    resetUser: () => set({ user: { name: '', email: '', role: '' } })
}));
root.render(

      <KindeProvider
          clientId="fbfd137b74d4469e845609b1856d7d4a"
          domain="https://saltroad.kinde.com"
          redirectUri="https://www.saltroad-gmp.com"
          logoutUri="https://www.saltroad-gmp.com"
          audience="sr-gmp"
      >
        <App />
      </KindeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
