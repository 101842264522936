import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Grid,
    Collapse,
    IconButton,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import dayjs from 'dayjs';
import { host } from "../tableConfig";
import { createAxiosWrapper } from "../Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import ReadOnlyScheduledProcessPanel from "../ReadOnlyScheduledProcessPanel";
import { formatDate } from "../Util";
import {BatchData} from "../types";

interface Ingredient {
    id: number;
    name: string;
    lot: string;
}

interface Product {
    id: number;
    name: string;
    count: string;
    toll: number;
}

interface BatchDialogReadOnlyProps {
    open: boolean;
    onClose: () => void;
    batch: BatchData;
    process: number | '';
}

const BatchDialogReadOnly: React.FC<BatchDialogReadOnlyProps> = ({
                                                                     open,
                                                                     onClose,
                                                                     batch,
                                                                     process
                                                                 }) => {
    const [processData, setProcessData] = useState<any>({});
    const [isProcessExpanded, setIsProcessExpanded] = useState(false);
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const { getToken } = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        fetchProcess();
    }, []);

    const fetchProcess = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/scheduled-process/${process}`});
            const data = response.data;
            if (data.result === 'success') {
                setProcessData(data.data[0]);
                const fetchedIngredients = data.data[0].ingredients.map((ingredient: any) => ({
                    id: ingredient.id,
                    name: ingredient.name,
                    lot: batch.ingredients?.find((i:any) => i.ingredient === ingredient.id)?.lot || ''
                }));
                setIngredients(fetchedIngredients);

                const fetchedProducts = data.data[0].products.map((product: Product) => ({
                    id: product.id,
                    name: product.name,
                    count: batch.products?.find((p:any) => p.product === product.id)?.count || '',
                    toll: batch.products?.find((p:any) => p.product === product.id)?.toll || '',
                }));
                setProducts(fetchedProducts);
            }
        } catch (error) {
            console.error('Error fetching processes:', error);
        }
    };

    const toggleProcessExpansion = () => {
        setIsProcessExpanded(!isProcessExpanded);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: 0,
                    width: '100%',
                    maxHeight: '100%'
                },
            }}>
            <DialogTitle>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                    Batch #{batch.id}: {formatDate(batch.timestamp)}
                </Typography>
                <Typography sx={{ fontSize: '1.1rem' }}>
                    {batch.client_name} - {batch.process_name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={toggleProcessExpansion}>
                        <Typography variant="h5">Scheduled Process</Typography>
                        <IconButton>
                            {isProcessExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                    <Collapse in={isProcessExpanded}>
                        <ReadOnlyScheduledProcessPanel process={processData} />
                    </Collapse>
                </Box>
                <Typography variant="h5" mt={2}>Ingredient Lot Codes</Typography>
                <Box mt={2}>
                    <TableContainer component={Paper} style={{ maxWidth: '500px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Lot</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ingredients.map((ingredient) => (
                                    <TableRow key={ingredient.id} style={{ backgroundColor: ingredient.id % 2 === 0 ? '#f5f5f5' : 'white' }}>
                                        <TableCell>{ingredient.name}</TableCell>
                                        <TableCell>{ingredient.lot}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Typography variant="h5" mt={2}>Product Output</Typography>
                <Box mt={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Count</TableCell>
                                    <TableCell align="right">Toll</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map((product) => (
                                    <TableRow key={product.id} style={{ backgroundColor: product.id % 2 === 0 ? '#f5f5f5' : 'white' }}>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell align="right">{product.count}</TableCell>
                                        <TableCell align="right">{product.toll}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Typography variant="h5" mt={2}>Other Details</Typography>
                <Box mt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography>Labor Hours: {batch.labor_hours}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Exp Date: {batch.exp_date ? dayjs(batch.exp_date).format('MM/DD/YYYY') : 'N/A'}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Lot Code: {batch.lot || 'N/A'}</Typography>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Typography variant="h6">Output Notes</Typography>
                        <Typography>{batch.output_notes || 'No output notes'}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">Labor Notes</Typography>
                        <Typography>{batch.labor_notes || 'No labor notes'}</Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BatchDialogReadOnly;