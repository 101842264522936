import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    IconButton,
    Tooltip,
    Typography,
    Box,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import { host } from "../tableConfig";

interface FacilityDoc {
    id: number;
    name: string;
    end_date?: string;
    facility_num: number;
}

interface FacilityDocTableProps {
    documents: FacilityDoc[] | null;
    orderBy: string;
    order: 'asc' | 'desc';
    onSort: (property: string) => void;
    defaultSort?: string;
}

export const FacilityDocTable: React.FC<FacilityDocTableProps> = ({
                                                                      documents,
                                                                      orderBy,
                                                                      order,
                                                                      onSort,
                                                                      defaultSort = 'end_date'
                                                                  }) => {
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const handleDownload = async (doc: FacilityDoc) => {
        if (!doc.name) {
            console.error('No filename available for download');
            return;
        }

        try {
            const response = await axiosWrapper({
                url: `${host}/facility-docs/download/${doc.facility_num}/${doc.name}`,
                method: 'get',
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    if (!documents || documents.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 1,
                    margin: '20px 0 40px 0'
                }}
            >
                <Typography variant="h6" color="text.secondary">
                    No documents found
                </Typography>
            </Box>
        );
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                margin: '20px 0',
                marginBottom: '40px'
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Document Name</TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'end_date'}
                                direction={orderBy === 'end_date' ? order : 'asc'}
                                onClick={() => onSort('end_date')}
                            >
                                Expiration Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Download</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((doc) => (
                        <TableRow key={doc.id}>
                            <TableCell>{doc.name}</TableCell>
                            <TableCell>
                                {doc.end_date ? new Date(doc.end_date).toLocaleDateString() : '-'}
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Download Document">
                                    <IconButton
                                        onClick={() => handleDownload(doc)}
                                        size="small"
                                    >
                                        <DescriptionIcon
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    color: 'primary.main'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FacilityDocTable;