import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Button,
    Link
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { createAxiosWrapper } from './Util';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { host } from './tableConfig';
import useUserStore from './store/userStore';

const CatalogPanel: React.FC = () => {
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedClientName, setSelectedClientName] = useState<string>('');
    const [catalogData, setCatalogData] = useState<any>(null);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const { user } = useUserStore();
    const clients = useMemo(() => user?.clients || [], [user?.clients]);
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            setSelectedClientName(clients[0]?.name || user?.organization || '');
        }
    }, [isSingleClient, user?.clientId, clients, user?.organization]);

    useEffect(() => {
        if (selectedClientId) {
            fetchCatalogData();
        }
    }, [selectedClientId]);

    const fetchCatalogData = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/product-catalog/${selectedClientId}` });
            setCatalogData(response.data.data);
        } catch (error) {
            console.error('Error fetching catalog data:', error);
        }
    };

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };

    const downloadCSV = () => {
        if (!catalogData) return;

        let csvContent = "Category,Name,Case,Weight/Volume,Metric,UPC,SKU,Case_Price_Dist,Case_Price_Store,Notes\n";

        Object.entries(catalogData).forEach(([category, products]) => {
            (products as any[]).forEach((product) => {
                const row = [
                    category,
                    product.name,
                    product.case_size,
                    product.weight_or_volume,
                    product.metric,
                    product.upc,
                    product.sku,
                    product.case_price_dist,
                    product.case_price_store,
                    product.notes
                ].map(value => `"${value}"`).join(',');
                csvContent += row + "\n";
            });
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `catalog_${selectedClientName}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const clientMenuItems = useMemo(() =>
            clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    return (
        <Box sx={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
                {!isSingleClient ? (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            sx={{ width: '250px' }}
                            onChange={handleClientChange}
                        >
                            {clientMenuItems}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientName && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Client: {selectedClientName}
                        </Typography>
                    )
                )}
            </Box>
            {selectedClientId && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, alignItems: 'center', gap: 4 }}>
                    <Link
                        href={`https://www.saltroadapp.com/pages/product-catalog.html?clientId=${selectedClientId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Product Sell Sheet
                    </Link>
                    <Link
                        href={`https://www.saltroadapp.com/pages/product-catalog-dpr.html?clientId=${selectedClientId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sell Sheet + Distributor Pricing
                    </Link>
                    <Link
                        href={`https://www.saltroadapp.com/pages/product-catalog-spr.html?clientId=${selectedClientId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sell Sheet + Store Pricing
                    </Link>
                    <Button sx={{mt:2}} variant="contained" onClick={downloadCSV}>
                        Download Catalog
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default CatalogPanel;