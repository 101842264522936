import React, { useEffect, useState } from "react";
import { Box, Typography, Select, MenuItem, Button, SelectChangeEvent } from '@mui/material';
import MenuActionButton from './MenuActionButton';
import { PhotoCamera } from "@mui/icons-material";
import { host } from "./tableConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "./Util";
import AlbumDialog from "./AlbumDialog";

interface InventoryMenuProps {
    onAction: (action: string) => void;
}

const PhotoMenu: React.FC<InventoryMenuProps> = ({ onAction }) => {
    const [albums, setAlbums] = useState<any[]>([]);
    const [selectedAlbum, setSelectedAlbum] = useState('');
    const [open, setOpen] = useState(false);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        fetchAlbums();
    }, []);

    const fetchAlbums = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/getSQLResults/list_albums` });
            const data = response.data;
            if (data.result === 'success') {
                setAlbums(data.data);
            }
        } catch (error) {
            console.error('Error fetching albums:', error);
        }
    };

    const handleAlbumChange = (event: SelectChangeEvent<string>) => {
        setSelectedAlbum(event.target.value as string);
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            maxWidth: '300px',
            margin: '20px auto',
        }}>
            <MenuActionButton
                icon={<PhotoCamera />}
                title="Photo Capture"
                action="Other/Photo Capture"
                onAction={onAction}
            />
            <Typography sx={{ textAlign: 'center', color: '#1976D2', marginTop: '30px', marginBottom: '16px' }} variant="h4">
                Albums
            </Typography>
            <Select
                value={selectedAlbum}
                onChange={handleAlbumChange}
                displayEmpty
                fullWidth
                sx={{ marginBottom: '16px' }}
            >
                <MenuItem value="" disabled>Select an album</MenuItem>
                {albums.map((album) => (
                    <MenuItem key={album.id} value={album.id}>{album.name}</MenuItem>
                ))}
            </Select>
            <Button variant="contained" onClick={handleOpenDialog} fullWidth disabled={!selectedAlbum}>
                View
            </Button>

            <AlbumDialog
                open={open}
                onClose={handleCloseDialog}
                albumId={selectedAlbum}
                albumName={albums.find(album => album.id === selectedAlbum)?.name || ''}
            />
        </Box>
    );
};

export default PhotoMenu;