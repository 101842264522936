import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';

interface BottomTabBarProps {
    value: number;
    onChange: (newValue: number) => void;
}

const BottomTabBar: React.FC<BottomTabBarProps> = ({ value, onChange }) => {
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                sx={{paddingBottom: '10px' }}
                showLabels>
                <BottomNavigationAction icon={<HomeIcon />} />
                <BottomNavigationAction icon={<AccountTreeOutlinedIcon />} />
                <BottomNavigationAction icon={<QrCodeIcon />} />
                <BottomNavigationAction icon={<AccountCircleIcon />} />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomTabBar;