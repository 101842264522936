import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import {host} from './tableConfig'
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {createAxiosWrapper} from "./Util";

interface MarkdownDisplayProps {
    fileName: string;
}

const MarkdownDisplay: React.FC<MarkdownDisplayProps> = ({ fileName }) => {
    const [markdownContent, setMarkdownContent] = useState<string>('');
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        const fetchMarkdownContent = async () => {
            try {
                const response = await axiosWrapper({url:`${host}/markdown/${fileName}`});
                if (!response.data) {
                    throw new Error('Failed to fetch markdown content');
                }
                const content = response.data;
                setMarkdownContent(content);
            } catch (error) {
                console.error('Error fetching markdown content:', error);
                setMarkdownContent('Error loading markdown content');
            }
        };

        fetchMarkdownContent();
    }, [fileName]);

    return (
        <div className="markdown-container">
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
    );
};

export default MarkdownDisplay;