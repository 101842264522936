import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { createAxiosWrapper, getPriceAsNumber } from "../Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { formatDate } from "../Util";
import { BatchData } from "../types";
import BatchHeader from "./BatchHeader";
import BatchDetailsForm from "./BatchDetailsForm";
import { host } from "../tableConfig";
import dayjs from 'dayjs';
import { FormData, Ingredient, Product } from './batchTypes';

interface BatchDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (batch: BatchData, isDone: boolean) => void;
    batch: BatchData;
    process: number | '';
}

const BatchDialog = ({
                         open,
                         onClose,
                         onSave,
                         batch,
                         process
                     }: BatchDialogProps): JSX.Element => {
    const initialFormData: FormData = {
        outputNotes: batch.output_notes || '',
        laborNotes: batch.labor_notes || '',
        laborHours: batch.labor_hours || '',
        count: batch.count || '',
        lotCode: batch.lot || '',
        expDate: batch.exp_date ? dayjs(batch.exp_date) : null
    };

    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [processData, setProcessData] = useState<any>({});
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        setFormData({
            outputNotes: batch.output_notes || '',
            laborNotes: batch.labor_notes || '',
            laborHours: batch.labor_hours || '',
            count: batch.count || '',
            lotCode: batch.lot || '',
            expDate: batch.exp_date ? dayjs(batch.exp_date) : null
        });
    }, [batch]);

    useEffect(() => {
        fetchProcess();
    }, []);

    const fetchProcess = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/scheduled-process/${process}` });
            const data = response.data;
            if (data.result === 'success') {
                setProcessData(data.data[0]);
                const fetchedIngredients = data.data[0].ingredients.map((ingredient: any) => {
                    const batchIngredient = batch.ingredients?.find((i:any) => i.ingredient === ingredient.id);
                    return {
                        id: ingredient.id,
                        name: ingredient.name,
                        lot: batchIngredient?.lot || '',
                        price: getPriceAsNumber(batchIngredient?.price || ingredient.ref_price),
                        metricName: ingredient.metricName,
                        ref_metricName: ingredient.ref_metricName,
                    };
                });
                setIngredients(fetchedIngredients);

                const fetchedProducts = data.data[0].products.map((product: any) => {
                    const batchProduct = batch.products?.find((p:any) => p.product === product.id);
                    const tollValue = batchProduct?.toll ?? '0';
                    return {
                        id: product.id,
                        name: product.name,
                        count: batchProduct?.count || '',
                        toll: typeof tollValue === 'string' ?
                            parseFloat(tollValue.replace('$', '')) :
                            tollValue
                    };
                });
                setProducts(fetchedProducts);
            }
        } catch (error) {
            console.error('Error fetching processes:', error);
        }
    };

    const handleSave = (isDone: boolean) => {
        const savedIngredients = ingredients.map(({ id, lot, price }) => ({ id, lot, price }));
        const savedProducts = products.map(({ id, count, toll }) => ({
            id,
            count,
            toll: Number(toll.toFixed(2))
        }));

        onSave(
            {
                ...batch,
                output_notes: formData.outputNotes,
                labor_notes: formData.laborNotes,
                labor_hours: formData.laborHours,
                lot: formData.lotCode,
                count: formData.count,
                exp_date: formData.expDate ? formData.expDate.toISOString() : null,
                ingredients: savedIngredients,
                products: savedProducts,
            },
            isDone
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: 0,
                    width: '100%',
                    maxHeight: '100%',
                    backgroundColor: '#fff',
                },
            }}>
            <BatchHeader batch={batch} formatDate={formatDate} />
            <DialogContent sx={{ p: 0 }}>
                <BatchDetailsForm
                    processData={processData}
                    ingredients={ingredients}
                    setIngredients={setIngredients}
                    products={products}
                    setProducts={setProducts}
                    formData={formData}
                    setFormData={setFormData}
                />
            </DialogContent>
            <DialogActions sx={{ p: 3, backgroundColor: 'white' }}>
                <Button onClick={onClose} variant="outlined">Cancel</Button>
                <Button onClick={() => handleSave(false)} variant="contained" color="primary">Save</Button>
                <Button onClick={() => handleSave(true)} variant="contained" color="primary">Done</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BatchDialog;