import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { LocalizationProvider, DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {host} from './tableConfig'
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {createAxiosWrapper} from "./Util";

interface Inspection {
    id: number;
    timestamp: string;
    list_id: string;
    values: { [key: string]: number }[];
}

interface InspectionCalendarProps {
    listId: number;
}

interface CustomDayProps extends PickersDayProps<Dayjs> {
    highlightedDays?: Dayjs[];
}

const InspectionCalendar: React.FC<InspectionCalendarProps> = ({ listId }) => {
    const [inspections, setInspections] = useState<Inspection[]>([]);
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosWrapper({url:`${host}/inspection-list/${listId}`});
                const { inspection } = response.data.data;
                setInspections(inspection);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [listId]);

    const highlightedDays = inspections.map(inspection => dayjs(inspection.timestamp).startOf('day'));

    const CustomDay = (props: CustomDayProps) => {
        const { day, outsideCurrentMonth, highlightedDays = [], ...other } = props;
        const isHighlighted = !outsideCurrentMonth && highlightedDays.some(d => d.isSame(day, 'day'));

        return (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                sx={{
                    backgroundColor: isHighlighted ? 'mediumseagreen' : 'inherit',
                    color: isHighlighted ? 'white' : 'inherit',
                    borderRadius: 0,
                    border: 'none',
                    '&:hover': {
                        backgroundColor: isHighlighted ? 'darkgreen' : 'inherit',
                    },
                    '&.Mui-selected': {
                        backgroundColor: isHighlighted ? 'limegreen' : 'inherit',
                        color: isHighlighted ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: isHighlighted ? 'darkgreen' : 'inherit',
                        },
                    },
                }}
            />
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', backgroundColor: '#f9f9ff', border: '1px solid #ddf' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                    slots={{
                        day: (props: PickersDayProps<Dayjs>) => <CustomDay {...props} highlightedDays={highlightedDays} />,
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default InspectionCalendar;