import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { ScheduledProcess } from './types';

interface ReadOnlyScheduledProcessPanelProps {
    process: ScheduledProcess;
}

const ReadOnlyScheduledProcessPanel: React.FC<ReadOnlyScheduledProcessPanelProps> = ({ process }) => {
    return (
        <Box>
            <Typography variant="h6">Client</Typography>
            <Typography variant="body1">{process.clientName}</Typography>

            <Typography variant="h6">Name</Typography>
            <Typography variant="body1">{process.name}</Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>Ingredients</Typography>
            <TableContainer
                component={Paper}
                sx={{
                    maxWidth: '500px',
                    '& .MuiTableRow-root:nth-of-type(odd)': {
                        backgroundColor: 'grey.100'
                    }
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Count</TableCell>
                            <TableCell>Metric</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {process.ingredients?.map((ingredient, index) => (
                            <TableRow key={index}>
                                <TableCell>{ingredient.name}</TableCell>
                                <TableCell align="right">{ingredient.count}</TableCell>
                                <TableCell>{ingredient.metricName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6" sx={{ mt: 2 }}>Packaging</Typography>
            <TableContainer
                component={Paper}
                sx={{
                    maxWidth: '500px',
                    '& .MuiTableRow-root:nth-of-type(odd)': {
                        backgroundColor: 'grey.100'
                    }
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {process.packaging?.map((pack, index) => (
                            <TableRow key={index}>
                                <TableCell>{pack.name}</TableCell>
                                <TableCell align="right">{pack.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6" sx={{ mt: 2 }}>Products</Typography>
            <TableContainer
                component={Paper}
                sx={{
                    '& .MuiTableRow-root:nth-of-type(odd)': {
                        backgroundColor: 'grey.100'
                    }
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {process.products?.map((product, index) => (
                            <TableRow key={index}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell align="right">{product.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6" sx={{ mt: 2 }}>Quality Parameters</Typography>
            <ReactMarkdown>{process.qualityParams}</ReactMarkdown>

            <Typography variant="h6" sx={{ mt: 2 }}>Packaging Specifications</Typography>
            <ReactMarkdown>{process.packagingSpecs}</ReactMarkdown>

            <Typography variant="h6" sx={{ mt: 2 }}>Setup</Typography>
            <ReactMarkdown>{process.setup}</ReactMarkdown>

            <Typography variant="h6" sx={{ mt: 2 }}>Process</Typography>
            <ReactMarkdown>{process.process}</ReactMarkdown>

            <Typography variant="h6" sx={{ mt: 2 }}>Notes</Typography>
            <ReactMarkdown>{process.notes}</ReactMarkdown>
        </Box>
    );
};

export default ReadOnlyScheduledProcessPanel;