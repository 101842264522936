import React from 'react';
import {
    Box,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField
} from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield';
import { Product } from '../batchTypes';
import HelpDialog, {HelpIconButton} from "./HelpDialog";
import {OTHER_DETAILS_HELP_CONTENT, PRODUCT_HELP_CONTENT} from "./helpContent";

interface ProductSectionProps {
    products: Product[];
    setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
}

const ProductSection: React.FC<ProductSectionProps> = ({
                                                           products,
                                                           setProducts
                                                       }) => {
    const [helpOpen, setHelpOpen] = React.useState(false);

    const handleCountChange = (id: number, value: string) => {
        setProducts(products.map(product =>
            product.id === id ? { ...product, count: value } : product
        ));
    };

    const handleTollChange = (id: number, value: number | null) => {
        setProducts(products.map(product =>
            product.id === id ? { ...product, toll: value !== null ? Number(value) : 0 } : product
        ));
    };

    return (
        <Box sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            backgroundColor: 'white',
            mb: 2
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                px: 3,
                pt: 3,
                pb: 2
            }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: 'primary.main',
                        fontWeight: 600,
                        flexGrow: 1
                    }}>
                    Product Output
                </Typography>
                <HelpIconButton onClick={() => setHelpOpen(true)} />
            </Box>
            <HelpDialog
                open={helpOpen}
                onClose={() => setHelpOpen(false)}
                content={PRODUCT_HELP_CONTENT}
                title="Product Output Section Help"  // optional
            />
            <TableContainer component={Paper} sx={{
                boxShadow: 'none',
                '& .MuiTable-root': {
                    borderTop: 1,
                    borderColor: 'divider'
                }
            }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'primary.main' }}>
                            <TableCell sx={{ color: 'white', fontWeight: 600 }}>Name</TableCell>
                            <TableCell align="right" sx={{ color: 'white', fontWeight: 600 }}>Count</TableCell>
                            <TableCell align="right" sx={{ color: 'white', fontWeight: 600 }}>Toll</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((product) => (
                            <TableRow key={product.id}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        type="number"
                                        value={product.count}
                                        onChange={(e) => handleCountChange(product.id, e.target.value)}
                                        size="small"
                                        inputProps={{
                                            style: { textAlign: 'right' },
                                        }}
                                        sx={{ backgroundColor: 'white' }}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <CurrencyTextField
                                        value={product.toll}
                                        onChange={(event, value) => handleTollChange(product.id, value)}
                                        currencySymbol="$"
                                        decimalCharacter="."
                                        digitGroupSeparator=","
                                        size="small"
                                        textAlign="right"
                                        sx={{ backgroundColor: 'white' }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ProductSection;