import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
    CircularProgress,
    Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import DashboardPlugin from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import { UppyFile } from '@uppy/core';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { host } from "../tableConfig";
import useUserStore from "../store/userStore";
import { format } from 'date-fns';

interface AddInspectionDialogProps {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
}

interface FileMetadata {
    inspection_date: string;
    notes: string;
    facility_num: number;
}

type UppyFileType = UppyFile<Record<string, unknown>, Record<string, unknown>>;

interface UppyResult {
    successful: Array<{
        id: string;
        meta: Record<string, any>;
        response: {
            body: any;
            status: number;
            statusText: string;
        };
    }>;
    failed: Array<any>;
}

interface UploadErrorResponse {
    message?: string;
}

const AddInspectionDialog: React.FC<AddInspectionDialogProps> = ({
                                                                     open,
                                                                     onClose,
                                                                     onComplete,
                                                                 }) => {
    const [inspectionDate, setInspectionDate] = useState<Date | null>(new Date());
    const [notes, setNotes] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const uppyInstance = useRef<Uppy | null>(null);

    const { getToken } = useKindeAuth();
    const { user } = useUserStore();
    const facilityNum = useMemo(() => user?.facility_num || 0, [user]);

    useEffect(() => {
        const initUppy = async () => {
            if (!getToken) {
                console.error('getToken is not available');
                return;
            }

            try {
                const token = await getToken();

                uppyInstance.current = new Uppy({
                    restrictions: {
                        maxNumberOfFiles: 1,
                        allowedFileTypes: ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png']
                    },
                    autoProceed: false,
                })
                    .use(Webcam, {
                        mirror: true,
                        facingMode: 'environment',
                        showRecordingLength: true,
                        modes: ['picture'],
                    })
                    .use(ThumbnailGenerator, {
                        thumbnailWidth: 200,
                        thumbnailHeight: 200,
                        waitForThumbnailsBeforeUpload: true,
                    })
                    .use(DashboardPlugin, {
                        inline: true,
                        target: '.uppyDashboard',
                        showProgressDetails: true,
                        height: 400,
                        width: '100%',
                        hideCancelButton: true,
                        hideUploadButton: true,
                        hideRetryButton: true,
                        hidePauseResumeButton: true,
                        showRemoveButtonAfterComplete: true,
                        proudlyDisplayPoweredByUppy: false,
                        fileManagerSelectionType: 'files',
                        theme: 'light',
                        thumbnailType: 'image/png',
                        plugins: ['Webcam'],
                        locale: {
                            strings: {
                                dropPasteImport: 'Drag & drop files here or %{browse}',
                                browse: 'select from device',
                                uploadComplete: 'Upload successful',
                                documentPreviewError: 'Failed to generate preview',
                                takePicture: 'Take a photo',
                                smile: 'Smile!',
                                noCameraTitle: 'Camera Not Available',
                                noCameraDescription: 'To take pictures, please connect a camera device',
                                allowAccessTitle: 'Please allow access to your camera',
                                allowAccessDescription: 'To take pictures, please allow camera access for this site.'
                            }
                        }
                    })
                    .use(XHRUpload, {
                        endpoint: `${host}/pest-control-docs`,
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        formData: true,
                        fieldName: 'document',
                    });

                const uppy = uppyInstance.current;

                uppy.on('upload', () => {
                    setIsUploading(true);
                    setUploadError(null);
                });

                uppy.on('file-added', (file: UppyFileType) => {
                    const currentFormattedDate = inspectionDate ? format(inspectionDate, 'yyyy-MM-dd') : '';
                    uppy.setFileMeta(file.id, {
                        inspection_date: currentFormattedDate,
                        notes: notes,
                        facility_num: facilityNum
                    });
                });

                uppy.on('upload-success', (file: UppyFileType) => {
                    setIsUploading(false);
                    onComplete();
                    handleClose();
                });

                uppy.on('upload-error', (file: UppyFileType, error: UploadErrorResponse) => {
                    setIsUploading(false);
                    setUploadError(error.message || 'Upload failed');
                });

                uppy.on('complete', (result: UppyResult) => {
                    if (result.failed.length > 0) {
                        setUploadError('Upload failed. Please try again.');
                    }
                    setIsUploading(false);
                });

                uppy.on('webcam:start', () => {
                    console.log('Camera started');
                });

                uppy.on('webcam:stop', () => {
                    console.log('Camera stopped');
                });

                uppy.on('webcam:error', (error:any) => {
                    console.error('Camera error:', error);
                    setUploadError('Camera error: ' + error.message);
                });

                uppy.on('upload-progress', (file: UppyFileType, progress: { bytesUploaded: number, bytesTotal: number }) => {
                    const progressPercentage = (progress.bytesUploaded / progress.bytesTotal) * 100;
                    console.log(`Upload progress: ${progressPercentage}%`);
                });

                uppy.on('thumbnail:generated', (file: UppyFileType, preview: string) => {
                    console.log('Thumbnail generated for:', file.name, preview);
                });

                uppy.on('thumbnail:error', (file: UppyFileType, error: Error) => {
                    console.log('Thumbnail error:', error);
                });

            } catch (error) {
                console.error('Error initializing Uppy:', error);
                setUploadError('Failed to initialize upload. Please try again.');
            }
        };

        if (open) {
            initUppy();
        }

        return () => {
            if (uppyInstance.current) {
                try {
                    const files = uppyInstance.current.getFiles();
                    files.forEach((file: UppyFileType) => {
                        uppyInstance.current?.removeFile(file.id);
                    });

                    // Remove all event listeners
                    uppyInstance.current.off('upload');
                    uppyInstance.current.off('file-added');
                    uppyInstance.current.off('upload-success');
                    uppyInstance.current.off('upload-error');
                    uppyInstance.current.off('complete');
                    uppyInstance.current.off('upload-progress');
                    uppyInstance.current.off('thumbnail:generated');
                    uppyInstance.current.off('thumbnail:error');
                    uppyInstance.current.off('webcam:start');
                    uppyInstance.current.off('webcam:stop');
                    uppyInstance.current.off('webcam:error');

                    // Clean up plugins
                    const dashboard = uppyInstance.current.getPlugin('Dashboard');
                    if (dashboard) {
                        dashboard.unmount();
                    }

                    uppyInstance.current.close();
                    uppyInstance.current = null;
                } catch (error) {
                    console.error('Error during Uppy cleanup:', error);
                }
            }
        };
    }, [open, getToken, inspectionDate, notes, facilityNum, onComplete]);

    const handleStartUpload = async () => {
        if (!uppyInstance.current || !getToken) {
            setUploadError('Upload is not available');
            return;
        }

        try {
            const token = await getToken();
            const uppy = uppyInstance.current;
            const files = uppy.getFiles();

            if (files.length === 0) {
                setUploadError('No files selected');
                return;
            }

            // Update XHRUpload options with current form data
            const xhrUpload = uppy.getPlugin('XHRUpload');
            if (xhrUpload) {
                xhrUpload.setOptions({
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    formData: true,
                    fieldName: 'document',
                    metaFields: ['inspection_date', 'notes', 'facility_num'],
                });
            }

            // Set metadata for the upload
            const currentFormattedDate = inspectionDate ? format(inspectionDate, 'yyyy-MM-dd') : '';
            uppy.setMeta({
                inspection_date: currentFormattedDate,
                notes: notes,
                facility_num: facilityNum
            });

            uppy.upload();
        } catch (error) {
            console.error('Error starting upload:', error);
            setUploadError('Failed to start upload. Please try again.');
        }
    };

    const handleClose = () => {
        if (!isUploading) {
            setInspectionDate(new Date());
            setNotes('');
            setUploadError(null);
            if (uppyInstance.current) {
                const files = uppyInstance.current.getFiles();
                files.forEach((file: UppyFileType) => {
                    uppyInstance.current?.removeFile(file.id);
                });
            }
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80vh'
                }
            }}
        >
            <DialogTitle>Add Inspection Document</DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {uploadError && (
                        <Alert severity="error" onClose={() => setUploadError(null)}>
                            {uploadError}
                        </Alert>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Inspection Date"
                            value={inspectionDate}
                            onChange={(newValue: Date | null) => setInspectionDate(newValue)}
                            slotProps={{
                                textField: {
                                    required: true,
                                    fullWidth: true
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <TextField
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                    />
                    <div className="uppyDashboard"></div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ position: 'relative', display: 'flex', gap: 1 }}>
                    {isUploading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: -40,
                                marginTop: '-12px',
                            }}
                        />
                    )}
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={isUploading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleStartUpload}
                        color="primary"
                        disabled={isUploading}
                    >
                        Upload
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AddInspectionDialog;