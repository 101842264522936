import React from "react";
import { Box } from '@mui/material';
import { ReactComponent as AppleIcon } from './images/icons/apple.svg';
import MenuActionButton from './MenuActionButton';
import { LocalShipping, Person } from "@mui/icons-material";
import { Package } from "lucide-react";
import useUserStore from "./store/userStore";

interface InventoryMenuProps {
    onAction: (action: string) => void;
}

const InMenu: React.FC<InventoryMenuProps> = ({ onAction }) => {
    const iconStyle = { fontSize: '4rem' };
    const { user } = useUserStore();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            maxWidth: '300px',
            margin: '30px auto',
        }}>
            <MenuActionButton
                icon={<Person style={iconStyle}/>}
                title="Visitors"
                action="Other/Visitors"
                onAction={onAction}
            />
            <MenuActionButton
                icon={<LocalShipping style={iconStyle}/>}
                title="Deliveries"
                action="Ship+Receive/Deliveries"
                onAction={onAction}
            />
            <MenuActionButton
                icon={<AppleIcon style={{ width: '5rem', height: '5rem', fill: '#3b82f6' }} />}
                title="Ingredient Receiving"
                action="Ship+Receive/Ingredient Receiving"
                onAction={onAction}
            />
            <MenuActionButton
                icon={<Package size={64} color="#3b82f6" />}
                title="Packaging Receiving"
                action="Ship+Receive/Packaging Receiving"
                onAction={onAction}
            />
        </Box>
    );
};

export default InMenu;