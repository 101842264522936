import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, IconButton, Box, Chip
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { host } from './tableConfig';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "./Util";
import { BatchData } from "./types";
import useUserStore from "./store/userStore";

const BatchesByDate: React.FC<any> = () => {
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);
    const [batchData, setBatchData] = useState<BatchData[]>([]);
    const [currentWeekStart, setCurrentWeekStart] = useState(getStartOfWeek(new Date()));
    const { user } = useUserStore();

    useEffect(() => {
        fetchBatchData();
    }, []);

    const fetchBatchData = async () => {
        try {
            let filter = 'all';
            if (!user?.clients.length) {
                filter = `${user.clientId}`;
            }
            const response = await axiosWrapper({url: `${host}/getSQLResults/list_batches/${filter}`});
            const data = response.data;
            if (data.result === "success" && Array.isArray(data.data)) {
                setBatchData(data.data);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    function getStartOfWeek(date: Date) {
        const d = new Date(date);
        const day = d.getDay();
        const diff = d.getDate() - day;
        d.setDate(diff);
        // Set to midnight local time
        d.setHours(0, 0, 0, 0);
        return d;
    }

    function formatDate(date: Date) {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }

    function getWeekNumber(date: Date) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        const yearStart = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    }

    function getDayBatches(date: Date) {
        return batchData.filter(batch => {
            // Convert GMT timestamp to local date for comparison
            const batchTimestamp = batch.close_timestamp || batch.timestamp || '';
            const batchDate = new Date(batchTimestamp);

            // Set comparison dates to midnight local time
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);

            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);

            return batchDate >= startOfDay && batchDate <= endOfDay;
        });
    }

    function navigateWeek(direction: number) {
        const newDate = new Date(currentWeekStart);
        newDate.setDate(newDate.getDate() + direction * 7);
        if (newDate <= getStartOfWeek(new Date())) {
            setCurrentWeekStart(newDate);
        }
    }

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekDates = days.map((_, index) => {
        const date = new Date(currentWeekStart);
        date.setDate(date.getDate() + index);
        return date;
    });

    const isCurrentWeek = currentWeekStart.getTime() === getStartOfWeek(new Date()).getTime();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, px: 1 }}>
                <IconButton onClick={() => navigateWeek(-1)} size="small">
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h6" component="h2">
                    Week of {formatDate(currentWeekStart)} ({getWeekNumber(currentWeekStart)})
                </Typography>
                <IconButton onClick={() => navigateWeek(1)} disabled={isCurrentWeek} size="small">
                    <ChevronRight />
                </IconButton>
            </Box>
            <TableContainer component={Paper} sx={{ width: '100%', boxShadow: 'none' }}>
                <Table sx={{ width: '100%' }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '5%', minWidth: '50px', px: 1 }}>Day</TableCell>
                            <TableCell sx={{ px: 1 }}>Batches</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weekDates.map((date, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    backgroundColor: (index === 0 || index === 6) ? 'rgba(0, 0, 0, 0.04)' : 'inherit'
                                }}
                            >
                                <TableCell sx={{ width: '5%', minWidth: '50px', px: 1 }}>{days[index]}</TableCell>
                                <TableCell sx={{ px: 1 }}>
                                    {getDayBatches(date).map((batch, batchIndex) => (
                                        <Box
                                            key={batchIndex}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 0.5,
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                },
                                                borderRadius: 1,
                                                py: 0.25,
                                                backgroundColor: batch.close_timestamp ? 'inherit' : 'rgba(255, 0, 0, 0.1)',
                                            }}
                                        >
                                            <Chip
                                                label={batch.client_name}
                                                size="small"
                                                sx={{ mr: 1, height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.75rem' } }}
                                            />
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    flexGrow: 1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '0.75rem',
                                                    color: batch.close_timestamp ? 'inherit' : 'red',
                                                }}
                                            >
                                                {batch.id} : {batch.process_name}
                                            </Typography>
                                        </Box>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography
                variant="caption"
                sx={{
                    display: 'block',
                    mt: 1,
                    color: 'text.secondary',
                    px: 1
                }}
            >
                Batches in red have not been marked as done.
            </Typography>
        </Box>
    );
};

export default BatchesByDate;