import React, { useState } from 'react';
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { MenuItem } from './menuItems';

interface MenuItemWithSubItems extends MenuItem {
    subItems: MenuItem[];
}

function hasSubItems(item: MenuItem): item is MenuItemWithSubItems {
    return 'subItems' in item && Array.isArray((item as any).subItems);
}

interface SideMenuProps {
    isOpen: boolean;
    onClose: () => void;
    logout: () => void;
    handleNodeClick: (nodeName: string) => void;
    handleMenuAction: (action: string) => void;
    menuItems: MenuItem[]; // New prop to receive filtered menu items
}

export default function SideMenu({ isOpen, onClose, logout, handleNodeClick, handleMenuAction, menuItems }: SideMenuProps): JSX.Element {
    const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);

    const toggleSubmenu = (index: number): void => {
        setOpenSubmenu(openSubmenu === index ? null : index);
    };

    const customIconTitles: string[] = ['Ingredient Counts', 'Product Counts'];
    const isCustomIcon = (title: string | null): boolean => {
        if (!title) return false;
        return customIconTitles.includes(title);
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Escape') onClose();
                }}
            >
                <List>
                    {menuItems.map((item: MenuItem, index: number) => {
                        const itemHasSubItems: boolean = hasSubItems(item);
                        return (
                            <React.Fragment key={item.title}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        if (item.title === "Logout") {
                                            logout();
                                        } else if (itemHasSubItems) {
                                            toggleSubmenu(index);
                                        } else {
                                            handleMenuAction(item.title);
                                        }
                                    }}
                                >
                                    <ListItemIcon>{!isCustomIcon(item.title) ? item.icon : null}</ListItemIcon>
                                    <ListItemText primary={item.title} />
                                    {itemHasSubItems && (openSubmenu === index ? <ExpandLess /> : <ExpandMore />)}
                                </ListItem>
                                {itemHasSubItems && (
                                    <Collapse in={openSubmenu === index} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {(item as MenuItemWithSubItems).subItems.map((subItem: MenuItem) => (
                                                <ListItem
                                                    button
                                                    key={subItem.title}
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuAction(`${item.title}/${subItem.title}`)}
                                                >
                                                    <ListItemText primary={subItem.title} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Drawer>
    );
}