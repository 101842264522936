import React from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    Divider
} from '@mui/material';

interface Ingredient {
    id: number;
    name: string;
    price: number;
    metricName?: string;
    ref_metricName?: string;
}

interface ProcessIngredient {
    id: number;
    count: string;
}

interface IngredientCostPanelProps {
    ingredients: Ingredient[];
    processIngredients: ProcessIngredient[];
}

const IngredientCostPanel: React.FC<IngredientCostPanelProps> = ({
                                                                     ingredients,
                                                                     processIngredients
                                                                 }) => {
    const calculateIngredientTotal = (ingredient: Ingredient) => {
        if (ingredient.metricName === 'var') {
            return ingredient.price;
        }
        const scheduledIngredient = processIngredients?.find(i => i.id === ingredient.id);
        const amount = parseFloat(scheduledIngredient?.count || '0');
        return amount * ingredient.price;
    };

    const formatIngredientInfo = (ingredient: Ingredient, amount: string) => {
        if (ingredient.metricName === 'var') {
            return `price: $${ingredient.price}`;
        }
        return `${amount} ${ingredient.metricName} × $${ingredient.price}/${ingredient.ref_metricName || 'unit'}`;
    };

    const calculateBatchTotal = () => {
        return ingredients
            .filter(ingredient => ingredient.price > 0)
            .reduce((total, ingredient) => total + calculateIngredientTotal(ingredient), 0);
    };

    return (
        <Box sx={{ margin: '0 -24px' }}>
            <Box
                sx={{
                    backgroundColor: '#f5f5f5',
                    width: '100%',
                }}
            >
                <List disablePadding>
                    {ingredients
                        .filter(ingredient => ingredient.price > 0)
                        .map((ingredient, index, array) => {
                            const scheduledIngredient = processIngredients?.find(i => i.id === ingredient.id);
                            const amount = scheduledIngredient?.count || '0';
                            const totalCost = calculateIngredientTotal(ingredient);

                            return (
                                <React.Fragment key={ingredient.id}>
                                    <ListItem
                                        sx={{
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            py: 1,
                                            px: 3,
                                            gap: 0.5
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: 'bold',
                                                width: '100%'
                                            }}
                                        >
                                            {ingredient.name}
                                        </Typography>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 0.5
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{ color: 'text.secondary' }}
                                            >
                                                {formatIngredientInfo(ingredient, amount)}
                                            </Typography>
                                            {ingredient.metricName !== 'var' && (
                                                <Typography variant="body2">
                                                    Total: ${totalCost.toFixed(2)}
                                                </Typography>
                                            )}
                                        </Box>
                                    </ListItem>
                                    {index < array.length - 1 && (
                                        <Divider />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </List>
                <Box
                    sx={{
                        py: 2,
                        px: 3,
                        borderTop: '2px solid',
                        borderColor: 'divider'
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <span>Batch Total:</span>
                        <span>${calculateBatchTotal().toFixed(2)}</span>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default IngredientCostPanel;