import React, { useState, useEffect, useMemo } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
    Typography, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import AddInvoiceDialog from './AddInvoiceDialog';
import EditInvoiceDialog from './EditInvoiceDialog';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { calculateTotalAmount, createAxiosWrapper } from "./Util";
import useUserStore from './store/userStore';
import { host } from './tableConfig';
import { Invoice, Client, Batch } from './types';

const InvoiceTable: React.FC = () => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>(null);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedClientName, setSelectedClientName] = useState<string>('');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);
    const { user } = useUserStore();

    const clients = useMemo(() => user?.clients || [], [user?.clients]);
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            setSelectedClientName(clients[0]?.name || user?.organization || '');
        }
    }, [isSingleClient, user?.clientId, clients, user?.organization]);

    useEffect(() => {
        if (selectedClientId) {
            fetchInvoices();
        }
    }, [selectedClientId]);

    const fetchInvoices = async () => {
        if (!selectedClientId) return;
        try {
            const response = await axiosWrapper({ url: `${host}/invoice?clientId=${selectedClientId}` });
            if (response.data.result === 'success') {
                setInvoices(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
        }
    };

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };

    const handleAddInvoice = () => {
        setOpenAddDialog(true);
    };

    const handleEditInvoice = (id: number) => {
        setSelectedInvoiceId(id);
        setOpenEditDialog(true);
    };

    const handlePrintInvoice = (id: number) => {
        window.open(`https://www.saltroadapp.com/pages/av-invoice.html?invoice_num=${id}`, '_blank');
    };

    const handleCloseDialog = () => {
        setOpenAddDialog(false);
        setOpenEditDialog(false);
        setSelectedInvoiceId(null);
    };

    const handleSave = async (newInvoice: Omit<Invoice, 'id'| 'timestamp'>) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/invoice`,
                method: 'post',
                data: newInvoice,
            });
            if (response.data.result === 'success') {
                await fetchInvoices();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error saving invoice:', error);
            return false;
        }
    };

    const handleUpdate = async (updatedInvoice: Invoice) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/invoice/${updatedInvoice.id}`,
                method: 'put',
                data: updatedInvoice,
            });
            if (response.data.result === 'success') {
                await fetchInvoices();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating invoice:', error);
            return false;
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '20px', marginBottom: '20px' }}>
                {!isSingleClient ? (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            sx={{ width: '250px' }}
                            onChange={handleClientChange}
                        >
                            {clients.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientName && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Client: {selectedClientName}
                        </Typography>
                    )
                )}
                {selectedClientId && (
                    <Button variant="contained" color="primary" onClick={handleAddInvoice}>
                        Add Invoice
                    </Button>
                )}
            </Box>
            {selectedClientId && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices.map((invoice) => (
                                <TableRow key={invoice.id}>
                                    <TableCell>{new Date(invoice.timestamp).toLocaleDateString()}</TableCell>
                                    <TableCell>${calculateTotalAmount(invoice.batches).toFixed(2)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleEditInvoice(invoice.id!)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handlePrintInvoice(invoice.id!)}>
                                            <PrintIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <AddInvoiceDialog
                open={openAddDialog}
                onClose={handleCloseDialog}
                onSave={handleSave}
                clientId={selectedClientId as number}
                clientName={selectedClientName}
            />
            {selectedInvoiceId !== null && (
                <EditInvoiceDialog
                    open={openEditDialog}
                    onClose={handleCloseDialog}
                    onSave={handleUpdate}
                    invoiceId={selectedInvoiceId}
                    clientId={selectedClientId as number}
                    clientName={selectedClientName}
                />
            )}
        </div>
    );
};

export default InvoiceTable;