import React from 'react';
import { AppBar, Box, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface TopAppBarProps {
    onMenuClick: () => void;
    onTitleClick: () => void;
    userName: string | null;
    organization: string | null;
}

export default function TopAppBar({ onMenuClick, onTitleClick, userName, organization }: TopAppBarProps): JSX.Element {
    return (
        <AppBar position="static" sx={{ padding: '8px' }}>
            <Box onClick={onTitleClick} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography
                    variant="h4"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        textAlign: 'center',
                        marginRight: '-20px',
                        fontFamily: 'Archivo Black, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        letterSpacing: '0.1em',
                    }}
                >
                    SALT ROAD
                    <sup
                        style={{
                            fontSize: '0.4em',
                            verticalAlign: 'super',
                            position: 'relative',
                            top: '-0.4em',
                        }}
                    >
                        ®
                    </sup>
                </Typography>

                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    onClick={onMenuClick}>
                    <MenuIcon fontSize="large"/>
                </IconButton>
            </Box>
            <Box onClick={onTitleClick} sx={{cursor: 'pointer'}} >
                {userName && (
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        {userName} &nbsp;|&nbsp; {organization}
                    </Typography>
                )}
            </Box>
        </AppBar>
    );
}