import React, { useState } from 'react';
import { Box } from '@mui/material';
import { BatchDetailsFormProps } from './batchTypes';
import ProcessSection from './sections/ProcessSection';
import IngredientSection from './sections/IngredientSection';
import ProductSection from './sections/ProductSection';
import OtherDetailsSection from './sections/OtherDetailsSection';

const BatchDetailsForm: React.FC<BatchDetailsFormProps> = ({
                                                               processData,
                                                               ingredients,
                                                               setIngredients,
                                                               products,
                                                               setProducts,
                                                               formData,
                                                               setFormData
                                                           }) => {
    const [isProcessExpanded, setIsProcessExpanded] = useState(false);
    const [isIngredientsExpanded, setIsIngredientsExpanded] = useState(false);

    return (
        <>
            <ProcessSection
                processData={processData}
                isExpanded={isProcessExpanded}
                setIsExpanded={setIsProcessExpanded}
            />

            <IngredientSection
                ingredients={ingredients}
                setIngredients={setIngredients}
                processData={processData}
                isExpanded={isIngredientsExpanded}
                setIsExpanded={setIsIngredientsExpanded}
            />

            <ProductSection
                products={products}
                setProducts={setProducts}
            />

            <OtherDetailsSection
                formData={formData}
                setFormData={setFormData}
            />
        </>
    );
};

export default BatchDetailsForm;