import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
    CircularProgress,
    Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import DashboardPlugin from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import { UppyFile } from '@uppy/core';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { host } from "../tableConfig";
import useUserStore from "../store/userStore";
import { format } from 'date-fns';

interface AddFacilityDocDialogProps {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
}

interface FileMetadata {
    start_date?: string;
    end_date?: string;
    notes: string;
    facility_num: number;
}

type UppyFileType = UppyFile<Record<string, unknown>, Record<string, unknown>>;

interface UppyResult {
    successful: Array<{
        id: string;
        meta: Record<string, any>;
        response: {
            body: any;
            status: number;
            statusText: string;
        };
    }>;
    failed: Array<any>;
}

interface UploadErrorResponse {
    message?: string;
}

const AddFacilityDocDialog: React.FC<AddFacilityDocDialogProps> = ({
                                                                       open,
                                                                       onClose,
                                                                       onComplete,
                                                                   }) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [notes, setNotes] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const uppyInstance = useRef<Uppy | null>(null);

    const { getToken } = useKindeAuth();
    const { user } = useUserStore();
    const facilityNum = useMemo(() => user?.facility_num || 0, [user]);

    useEffect(() => {
        const initUppy = async () => {
            if (!getToken) {
                console.error('getToken is not available');
                return;
            }

            try {
                const token = await getToken();

                uppyInstance.current = new Uppy({
                    restrictions: {
                        maxNumberOfFiles: 1,
                        allowedFileTypes: ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png']
                    },
                    autoProceed: false,
                })
                    .use(Webcam, {
                        mirror: true,
                        facingMode: 'environment',
                        showRecordingLength: true,
                        modes: ['picture'],
                    })
                    .use(ThumbnailGenerator, {
                        thumbnailWidth: 200,
                        thumbnailHeight: 200,
                        waitForThumbnailsBeforeUpload: true,
                    })
                    .use(DashboardPlugin, {
                        inline: true,
                        target: '.uppyDashboard',
                        showProgressDetails: true,
                        height: 400,
                        width: '100%',
                        hideCancelButton: true,
                        hideUploadButton: true,
                        hideRetryButton: true,
                        hidePauseResumeButton: true,
                        showRemoveButtonAfterComplete: true,
                        proudlyDisplayPoweredByUppy: false,
                        fileManagerSelectionType: 'files',
                        theme: 'light',
                        thumbnailType: 'image/png',
                        plugins: ['Webcam'],
                    })
                    .use(XHRUpload, {
                        endpoint: `${host}/facility-docs`,
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        formData: true,
                        fieldName: 'document',
                    });

                const uppy = uppyInstance.current;

                uppy.on('upload', () => {
                    setIsUploading(true);
                    setUploadError(null);
                });

                uppy.on('file-added', (file: UppyFileType) => {
                    const metadata: FileMetadata = {
                        notes: notes,
                        facility_num: facilityNum
                    };

                    if (startDate) {
                        metadata.start_date = format(startDate, 'yyyy-MM-dd');
                    }
                    if (endDate) {
                        metadata.end_date = format(endDate, 'yyyy-MM-dd');
                    }

                    uppy.setFileMeta(file.id, metadata);
                });

                uppy.on('upload-success', (file: UppyFileType) => {
                    setIsUploading(false);
                    onComplete();
                    handleClose();
                });

                uppy.on('upload-error', (file: UppyFileType, error: UploadErrorResponse) => {
                    setIsUploading(false);
                    setUploadError(error.message || 'Upload failed');
                });

                uppy.on('complete', (result: UppyResult) => {
                    if (result.failed.length > 0) {
                        setUploadError('Upload failed. Please try again.');
                    }
                    setIsUploading(false);
                });

            } catch (error) {
                console.error('Error initializing Uppy:', error);
                setUploadError('Failed to initialize upload. Please try again.');
            }
        };

        if (open) {
            initUppy();
        }

        return () => {
            if (uppyInstance.current) {
                try {
                    const files = uppyInstance.current.getFiles();
                    files.forEach((file: UppyFileType) => {
                        uppyInstance.current?.removeFile(file.id);
                    });

                    uppyInstance.current.close();
                    uppyInstance.current = null;
                } catch (error) {
                    console.error('Error during Uppy cleanup:', error);
                }
            }
        };
    }, [open, getToken, startDate, endDate, notes, facilityNum, onComplete]);

    const handleStartUpload = async () => {
        if (!uppyInstance.current || !getToken) {
            setUploadError('Upload is not available');
            return;
        }

        try {
            const token = await getToken();
            const uppy = uppyInstance.current;
            const files = uppy.getFiles();

            if (files.length === 0) {
                setUploadError('No files selected');
                return;
            }

            const xhrUpload = uppy.getPlugin('XHRUpload');
            if (xhrUpload) {
                xhrUpload.setOptions({
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    formData: true,
                    fieldName: 'document',
                    metaFields: ['start_date', 'end_date', 'notes', 'facility_num'],
                });
            }

            const metadata: FileMetadata = {
                notes: notes,
                facility_num: facilityNum
            };

            if (startDate) {
                metadata.start_date = format(startDate, 'yyyy-MM-dd');
            }
            if (endDate) {
                metadata.end_date = format(endDate, 'yyyy-MM-dd');
            }

            uppy.setMeta(metadata);
            uppy.upload();
        } catch (error) {
            console.error('Error starting upload:', error);
            setUploadError('Failed to start upload. Please try again.');
        }
    };

    const handleClose = () => {
        if (!isUploading) {
            setStartDate(null);
            setEndDate(null);
            setNotes('');
            setUploadError(null);
            if (uppyInstance.current) {
                const files = uppyInstance.current.getFiles();
                files.forEach((file: UppyFileType) => {
                    uppyInstance.current?.removeFile(file.id);
                });
            }
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80vh'
                }
            }}
        >
            <DialogTitle>Add Facility Document</DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {uploadError && (
                        <Alert severity="error" onClose={() => setUploadError(null)}>
                            {uploadError}
                        </Alert>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Effective Date"
                            value={startDate}
                            onChange={(newValue: Date | null) => setStartDate(newValue)}
                            slotProps={{
                                textField: {
                                    fullWidth: true
                                }
                            }}
                        />
                        <DatePicker
                            label="Expiration Date"
                            value={endDate}
                            onChange={(newValue: Date | null) => setEndDate(newValue)}
                            slotProps={{
                                textField: {
                                    fullWidth: true
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <TextField
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                    />
                    <div className="uppyDashboard"></div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ position: 'relative', display: 'flex', gap: 1 }}>
                    {isUploading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: -40,
                                marginTop: '-12px',
                            }}
                        />
                    )}
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={isUploading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleStartUpload}
                        color="primary"
                        disabled={isUploading}
                    >
                        Upload
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AddFacilityDocDialog;