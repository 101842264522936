import React, { useEffect, useMemo, useState } from 'react';
import { Button, Box } from '@mui/material';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import useUserStore from '../store/userStore';
import { host } from "../tableConfig";
import { InspectionTable } from './InspectionTable';
import AddInspectionDialog from './AddInspectionDialog';
import AddFacilityDocDialog from './AddFacilityDocDialog';
import FacilityDocTable from './FacilityDocTable';
import { Inspection } from './types';

interface FacilityDoc {
    id: number;
    name: string;
    start_date?: string;
    end_date?: string;
    notes?: string;
    facility_num: number;
}

const PestControl = () => {
    const [inspections, setInspections] = useState<Inspection[] | null>(null);
    const [facilityDocs, setFacilityDocs] = useState<FacilityDoc[] | null>(null);
    const [inspectionOrderBy, setInspectionOrderBy] = useState('inspection_date');
    const [facilityDocOrderBy, setFacilityDocOrderBy] = useState('start_date');
    const [inspectionOrder, setInspectionOrder] = useState<'asc' | 'desc'>('desc');
    const [facilityDocOrder, setFacilityDocOrder] = useState<'asc' | 'desc'>('desc');
    const [openInspectionDialog, setOpenInspectionDialog] = useState(false);
    const [openFacilityDocDialog, setOpenFacilityDocDialog] = useState(false);

    const {getToken} = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const {user} = useUserStore();
    const isFacility = useMemo(() => user?.facility || false, [user]);
    const facilityNum = useMemo(() => user?.facility_num || 0, [user]);

    const sortInspections = (data: Inspection[], property: string, sortOrder: 'asc' | 'desc') => {
        return [...data].sort((a, b) => {
            if (property === 'inspection_date') {
                const dateA = new Date(a.inspection_date).getTime();
                const dateB = new Date(b.inspection_date).getTime();
                return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
            }
            return 0;
        });
    };

    const sortFacilityDocs = (data: FacilityDoc[], property: string, sortOrder: 'asc' | 'desc') => {
        return [...data].sort((a, b) => {
            if (property === 'start_date' || property === 'end_date') {
                const dateA = a[property] ? new Date(a[property]!).getTime() : 0;
                const dateB = b[property] ? new Date(b[property]!).getTime() : 0;
                return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
            }
            return 0;
        });
    };

    const fetchFacilityDocuments = async () => {
        try {
            const response = await axiosWrapper({
                url: `${host}/pest-control-inspections/${facilityNum}`
            });
            const data = response.data;
            if (data.result === 'success') {
                const sortedData = sortInspections(data.data, inspectionOrderBy, inspectionOrder);
                setInspections(sortedData);
            } else {
                setInspections([]);
            }
        } catch (error) {
            console.log('Error fetching inspections:', error);
            setInspections([]);
        }
    };

    const fetchFacilityDocs = async () => {
        try {
            const response = await axiosWrapper({
                url: `${host}/facility-docs/${facilityNum}`
            });
            const data = response.data;
            if (data.result === 'success') {
                const sortedData = sortFacilityDocs(data.data, facilityDocOrderBy, facilityDocOrder);
                setFacilityDocs(sortedData);
            } else {
                setFacilityDocs([]);
            }
        } catch (error) {
            console.log('Error fetching facility documents:', error);
            setFacilityDocs([]);
        }
    };

    useEffect(() => {
        if (facilityNum) {
            fetchFacilityDocuments();
            fetchFacilityDocs();
        }
    }, [facilityNum]);

    const handleInspectionSort = (property: string) => {
        const isAsc = inspectionOrderBy === property && inspectionOrder === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setInspectionOrder(newOrder);
        setInspectionOrderBy(property);

        if (inspections) {
            const sortedInspections = sortInspections(inspections, property, newOrder);
            setInspections(sortedInspections);
        }
    };

    const handleFacilityDocSort = (property: string) => {
        const isAsc = facilityDocOrderBy === property && facilityDocOrder === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setFacilityDocOrder(newOrder);
        setFacilityDocOrderBy(property);

        if (facilityDocs) {
            const sortedDocs = sortFacilityDocs(facilityDocs, property, newOrder);
            setFacilityDocs(sortedDocs);
        }
    };

    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: '20px',
                marginBottom: '20px',
                gap: 2
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenInspectionDialog(true)}
                >
                    Add Inspection Document
                </Button>
            </Box>

            <InspectionTable
                inspections={inspections}
                orderBy={inspectionOrderBy}
                order={inspectionOrder}
                onSort={handleInspectionSort}
            />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: '20px',
                marginBottom: '20px',
                gap: 2
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenFacilityDocDialog(true)}
                >
                    Add Facility Document
                </Button>
            </Box>

            <FacilityDocTable
                documents={facilityDocs}
                orderBy={facilityDocOrderBy}
                order={facilityDocOrder}
                onSort={handleFacilityDocSort}
            />

            <AddInspectionDialog
                open={openInspectionDialog}
                onClose={() => setOpenInspectionDialog(false)}
                onComplete={fetchFacilityDocuments}
            />

            <AddFacilityDocDialog
                open={openFacilityDocDialog}
                onClose={() => setOpenFacilityDocDialog(false)}
                onComplete={fetchFacilityDocs}
            />
        </div>
    );
};

export default PestControl;