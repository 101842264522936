import React, { useEffect, useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThreeWaySwitch from './ThreeWaySwitch';
import { host } from './tableConfig';
import { createAxiosWrapper } from './Util';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import useUserStore from "./store/userStore";

interface Item {
    id: number;
    name: string;
    type: number;
}

interface ListItem {
    id: number;
    name: string;
    status: string | null;
}

interface GroupedItems {
    [key: string]: Item[];
}

const statusMap: { [key: string]: number } = {
    red: 0,
    yellow: 1,
    green: 2,
    null: -1,
};

const PanelOne: React.FC = () => {
    const [groupedItems, setGroupedItems] = useState<GroupedItems>({});
    const [expanded, setExpanded] = useState<number | null>(null);
    const [listItems, setListItems] = useState<{ [key: number]: ListItem[] }>({});
    const [notes, setNotes] = useState<{ [key: number]: string }>({});
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    // Get user and clients from the store
    const { user } = useUserStore();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const clientId = user?.clientId || 0
                const response = await axiosWrapper({ url: `${host}/getSQLResults/lists?clientId=${clientId}` });
                const items: Item[] = response.data.data;
                const grouped = items.reduce((acc: GroupedItems, item) => {
                    let key;
                    if (item.type === 1) key = "Daily";
                    else if (item.type === 7) key = "Weekly";
                    else if (item.type === 30) key = "Monthly";
                    else key = "Other";

                    if (!acc[key]) acc[key] = [];
                    acc[key].push(item);
                    return acc;
                }, {});
                setGroupedItems(grouped);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchItems();
    }, []);

    const handleExpand = async (itemId: number) => {
        if (expanded === itemId) {
            setExpanded(null);
            return;
        }

        if (!listItems[itemId]) {
            try {
                const response = await axiosWrapper({ url: `${host}/getSQLResults/list_items/${itemId}` });
                const dataWithDefaultStatus = response.data.data.map((item: ListItem) => ({
                    ...item,
                    status: null,
                }));
                setListItems(prev => ({ ...prev, [itemId]: dataWithDefaultStatus }));
            } catch (error) {
                console.error('Error fetching list items:', error);
            }
        }

        setExpanded(itemId);
    };

    const handleSwitchChange = (itemId: number, listItemId: number, newValue: string) => {
        setListItems(prev => ({
            ...prev,
            [itemId]: prev[itemId].map(li =>
                li.id === listItemId ? { ...li, status: newValue } : li
            )
        }));
    };

    const handleNotesChange = (itemId: number, value: string) => {
        setNotes(prev => ({
            ...prev,
            [itemId]: value
        }));
    };

    const handleSave = async (itemId: number) => {
        const updatedListItems = listItems[itemId].map(listItem => ({
            [listItem.id]: statusMap[listItem.status || 'null']
        }));
        const payload = [{
            list_id: itemId,
            values: updatedListItems,
            notes: notes[itemId] || ''
        }];
        console.log(payload);

        try {
            const response = await axiosWrapper({ url: `${host}/insert-inspection`, data: payload, method: 'post' });
            console.log('Save successful:', response.data);
            setExpanded(null);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
            {Object.entries(groupedItems).map(([groupTitle, items]) => (
                <Box key={groupTitle} sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, marginLeft: '16px' }}>{groupTitle}</Typography>
                    {items.map(item => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === item.id}
                            onChange={() => handleExpand(item.id)}
                            sx={{
                                '&.MuiAccordion-root': {
                                    borderRadius: 0,
                                    boxShadow: 'none',  // Removes the shadow
                                    border: 'none',     // Removes all borders
                                    '&:not(:last-child)': {
                                        borderBottom: 0,
                                    },
                                    '&:before': {
                                        display: 'none',
                                    },
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${item.id}-content`}
                                id={`panel-${item.id}-header`}
                                sx={{
                                    '&.MuiAccordionSummary-root': {
                                        borderRadius: 0,
                                    },
                                }}
                            >
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {listItems[item.id]?.map(listItem => (
                                    <Box
                                        key={listItem.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '8px 0',
                                        }}
                                    >
                                        <Typography sx={{ textAlign: 'left' }} variant="body2">{listItem.name}</Typography>
                                        <ThreeWaySwitch
                                            value={listItem.status}
                                            onChange={(newValue) => handleSwitchChange(item.id, listItem.id, newValue)}
                                        />
                                    </Box>
                                ))}
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    label="Notes"
                                    value={notes[item.id] || ''}
                                    onChange={(e) => handleNotesChange(item.id, e.target.value)}
                                    sx={{ marginTop: '20px' }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => handleSave(item.id)}
                                    sx={{
                                        marginTop: '20px',
                                        backgroundColor: 'green',
                                        color: 'white',
                                        fontSize: '1.2rem',
                                        padding: '10px 20px',
                                        '&:hover': {
                                            backgroundColor: 'darkgreen',
                                        }
                                    }}>
                                    Save
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            ))}
        </Box>
    );
};

export default PanelOne;