import React, { useCallback } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ThreeWaySwitchProps {
    value: string | null;
    onChange: (value: string) => void;
}

const ThreeWaySwitch: React.FC<ThreeWaySwitchProps> = ({ value, onChange }) => {
    const playSound = useCallback((soundFile: string) => {
        const audio = new Audio(soundFile);
        audio.play();
    }, []);

    const handleChange = useCallback((_:any, newValue: string) => {
        onChange(newValue);
        if (newValue === 'green') {
            playSound('/sounds/success.mp3');
        }
        if (newValue === 'red') {
            playSound('/sounds/failure.mp3');
        }
        if (newValue === 'yellow') {
            playSound('/sounds/warning-bell.mp3');
        }
    }, [onChange, playSound]);

    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleChange}
            sx={{
                '& .MuiToggleButton-root': {
                    width: 40,
                    height: 40,
                    padding: 0,
                    borderRadius: '20px',
                    margin: 0,
                    marginRight: '3px',
                    border: '1px solid #666',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.28)',
                    },
                },
                '& .MuiToggleButton-root[value="red"]': {
                    backgroundColor: '#ffcccc',
                },
                '& .MuiToggleButton-root[value="yellow"]': {
                    backgroundColor: '#ffffcc',
                },
                '& .MuiToggleButton-root[value="green"]': {
                    backgroundColor: '#ccffcc',
                },
                '& .Mui-selected': {
                    border: '2px solid black',
                },
                '& .MuiToggleButton-root.Mui-selected[value="red"]': {
                    backgroundColor: 'red',
                },
                '& .MuiToggleButton-root.Mui-selected[value="yellow"]': {
                    backgroundColor: 'gold',
                },
                '& .MuiToggleButton-root.Mui-selected[value="green"]': {
                    backgroundColor: 'mediumseagreen',
                },
            }}
        >
            <ToggleButton value="red" />
            <ToggleButton value="yellow" />
            <ToggleButton value="green" />
        </ToggleButtonGroup>
    );
};

export default ThreeWaySwitch;