import React, { useEffect, useState, useCallback, useRef } from 'react';
import mermaid from 'mermaid';
import './ProductionFlowChart.css';
import { useChart } from './ChartContext';

interface FlowChartMenuProps {
    onNodeClick: (nodeName: string) => void;
    chartDefinition: string;
}

const FlowChartMenu: React.FC<FlowChartMenuProps> = ({ onNodeClick, chartDefinition }) => {
    const { setChartSvg } = useChart();
    const [key, setKey] = useState(0);
    const [localChartSvg, setLocalChartSvg] = useState<string | null>(null);
    const chartRef = useRef<HTMLDivElement>(null);

    const handleNodeClick = useCallback((nodeText: string) => {
        console.log('Node clicked:', nodeText);
        onNodeClick(nodeText);
    }, [onNodeClick]);

    const attachEventListeners = useCallback(() => {
        if (chartRef.current) {
            const nodeElements = chartRef.current.querySelectorAll('.node');
            nodeElements.forEach(node => {
                node.addEventListener('click', (event) => {
                    const nodeText = (event.currentTarget as Element).textContent;
                    if (nodeText) {
                        handleNodeClick(nodeText.trim());
                    }
                });
            });
        }
    }, [handleNodeClick]);

    const renderChart = useCallback(async () => {
        try {
            await mermaid.initialize({
                startOnLoad: false,
                theme: 'default',
                securityLevel: 'loose',
                flowchart: {
                    useMaxWidth: true,
                    htmlLabels: true,
                    curve: 'basis',
                },
            });

            const { svg } = await mermaid.render(`mermaid-${key}`, chartDefinition);
            setLocalChartSvg(svg);
            setChartSvg(svg);
        } catch (error) {
            console.error('Mermaid render error:', error);
        }
    }, [chartDefinition, setChartSvg, key]);

    useEffect(() => {
        renderChart();
    }, [renderChart]);

    useEffect(() => {
        const timer = setTimeout(() => {
            attachEventListeners();
        }, 100); // Small delay to ensure DOM is updated

        return () => clearTimeout(timer);
    }, [attachEventListeners, localChartSvg]);

    useEffect(() => {
        setKey(prev => prev + 1);
    }, [chartDefinition]);

    return (
        <div className="flow-chart-wrapper" style={{ marginTop: '0px' }}>
            <div
                ref={chartRef}
                key={key}
                className="chart-container"
                style={{marginBottom: '60px'}}
                dangerouslySetInnerHTML={{ __html: localChartSvg || '<div style="padding: 60px;">Loading chart...</div>' }}
            />
        </div>
    );
};

export default FlowChartMenu;