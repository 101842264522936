import React from 'react';
import { Button, Typography } from '@mui/material';

interface MenuActionButtonProps {
    icon: React.ReactNode;
    title: string;
    action: string;
    onAction: (action: string) => void;
}

const MenuActionButton: React.FC<MenuActionButtonProps> = ({ icon, title, action, onAction }) => {

    const handleClick = () => {
        // console.log("MenuActionButton clicked:", action);
        onAction(action);
    };

    return (
        <Button
            onClick={handleClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '120px',
                minWidth: '250px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: 60
            }}
        >
            {icon}
            <Typography variant="caption" sx={{ marginTop: '8px' }}>
                {title}
            </Typography>
        </Button>
    );
};

export default MenuActionButton;